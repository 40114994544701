import React from 'react';

import './style.scss';
import {ReactComponent as IconFacebook} from '../../assets/icons/social/facebook-circle.svg';
import {ReactComponent as IconTelegram} from '../../assets/icons/social/telegram-circle.svg';
import {ReactComponent as IconEmail} from '../../assets/icons/social/email-circle.svg';
import {ReactComponent as IconSlack} from '../../assets/icons/social/slack-circle.svg';
import {ReactComponent as IconBitcoin} from '../../assets/icons/social/bitcoin-circle.svg';
import {ReactComponent as IconMedium} from '../../assets/icons/social/medium-circle.svg';
import {ReactComponent as IconTwitter} from '../../assets/icons/social/twitter-circle.svg';
import {ReactComponent as IconGithub} from '../../assets/icons/social/github-circle.svg';
import config from "../../config";

function Footer() {
  return (
    <footer className="footer">
      <div className="footer-logo-group">
        <a href={ config.links.facebook } target="_blank" rel="noreferrer noopener">
          <IconFacebook className="footer-logo"/>
        </a>
        <a href={ config.links.telegram } target="_blank" rel="noreferrer noopener">
          <IconTelegram className="footer-logo"/>
        </a>
        <a href={ 'mailto:' + config.links.email } target="_blank" rel="noreferrer noopener">
          <IconEmail className="footer-logo"/>
        </a>
        <a href={ config.links.bitcointalk } target="_blank" rel="noreferrer noopener">
          <IconBitcoin className="footer-logo"/>
        </a>
        <a href={ config.links.medium } target="_blank" rel="noreferrer noopener">
          <IconMedium className="footer-logo"/>
        </a>
        <a href={ config.links.twitter } target="_blank" rel="noreferrer noopener">
          <IconTwitter className="footer-logo"/>
        </a>
        <a href={ config.links.github } target="_blank" rel="noreferrer noopener">
          <IconGithub className="footer-logo"/>
        </a>
      </div>
      <div className="footer-copyright">
        <div style={{marginLeft: '18px'}}>© Copyright MyWish 2021, {' '}</div>
          <div>
            <a className="link" href={ config.links.policy } target="_blank" rel="noreferrer noopener">
              Privacy policy
            </a> | {' '}
            <a className="link" href={ config.links.terms } target="_blank" rel="noreferrer noopener">
              Terms of Service
            </a>
          </div>
      </div>
    </footer>
  );
}

export default Footer;
