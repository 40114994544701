import Web3 from 'web3';
import BigNumber from "bignumber.js";
import {isEqual} from 'lodash/lang';
import config from '../../config';

export default class MetamaskService {
  constructor({networkFrom,currentToken, contractDetails}) {
    console.log('MetamaskService',contractDetails)
    console.log('networkFrom', networkFrom);
    this.networkFrom = networkFrom;
    this.name = 'metamask'
    this.currentToken = currentToken;
    this.wallet = window.ethereum;
    this.net = config.IS_PRODUCTION ? 'mainnet' : 'testnet'
    this.nets = {
      mainnet: {
        'Ethereum': {
          name: 'Mainnet',
          code: '0x1',
        },
        'Binance-Chain': {
          name: 'Binance chain',
          code: 'Binance-Chain-Tigris', //TODO: проверить
        },
        'Binance-Smart-Chain': {
          name: 'Binance smart chain',
          code: '0x38',
        },
        'Xinfin': {
          name: 'Xinfin',
          code: '0x32'
        }
      },
      testnet: {
        'Ethereum': {
          name: 'Ropsten testnet',
          code: '0x3',
        },
        'Binance-Chain': {
          name: 'Binance chain testnet',
          code: 'Binance-Chain-Ganges', //TODO: проверить
        },
        'Binance-Smart-Chain': {
          name: 'Binance smart chain testnet',
          code: '0x61',
        },
      },
    }
    this.providers = {};
    this.Web3Provider = new Web3(this.wallet);
    this.wallet.on('chainChanged', (newChain) => {
      window.location.reload()
    });
    this.wallet.on('accountsChanged', (newAccounts) => {
      console.log('accountsChanged',newAccounts)
      const accounts = JSON.parse(localStorage.getItem('accounts'))
      if (!accounts || !isEqual(accounts.accounts,newAccounts)) {
        localStorage.setItem('accounts',JSON.stringify({accounts:newAccounts}))
      }
      window.location.reload();
    });
    this.contractAddressToken = contractDetails.ADDRESS.TOKEN[networkFrom];
    this.contractAddressXDCEToken = contractDetails.XDCE_ADDRESS[networkFrom];
    this.contractAddressSwap = contractDetails.ADDRESS.SWAP[networkFrom];
    this.contractAbiToken = contractDetails.ABI.TOKEN[networkFrom];
    this.contractAbiXDCEToken = contractDetails.XDCE_ABI[networkFrom];
    this.contractAbiSwap = contractDetails.ABI.SWAP[networkFrom];
    this.contractDecimals = contractDetails.DECIMALS.TOKEN[networkFrom];
    this.contractAbiXDCESwap = contractDetails.XDCE_SWAP_ABI[networkFrom];
    this.contractAddressXDCESwap = contractDetails.XDCE_SWAP_ADDRESS[networkFrom];
  }

  getAccount() {
    if (!this.wallet) {
      return {
        errorMsg: `${this.name} wallet is not injected`
      }
    }
    return new Promise((resolve, reject) => {
      let net,usedNet;
      if ((config.IS_PRODUCTION || config.IS_XINFIN) && this.networkFrom !== 'Xinfin') {
        net = 'mainnet'
        usedNet = '0x1'
      }
      if (!config.IS_PRODUCTION && this.networkFrom !== 'Xinfin') {
        net = 'ropsten'
        usedNet = '0x3'
      }
      if (this.networkFrom === 'Xinfin') {
        net = 'Xinfin'
        usedNet = '0x33'
      }

      const netVersion = this.wallet.chainId
      console.log('getAccount netVersion', netVersion)
      if (!netVersion || netVersion===null) {
        this.wallet.request({ method: 'eth_chainId' })
        .then(netVersion => {
          if (netVersion === usedNet) {
            this.wallet.request({ method: 'eth_requestAccounts' })
            .then(account => resolve({
              address: account[0],
              network: netVersion,
            }))
            .catch(_ => reject({ errorMsg: 'Not authorized' }))
          } else {
            reject({
              errorMsg: 'Please choose ' + net + ' network in metamask wallet'
            })
          }
        })
        .catch(_ => reject({ errorMsg: 'Not authorized' }))
      } else {
        if (netVersion === usedNet) {
          this.wallet.request({ method: 'eth_requestAccounts' })
          .then(account => resolve({
            address: account[0],
            network: netVersion,
          }))
          .catch(_ => reject({ errorMsg: 'Not authorized' }))
        } else {
          reject({
            errorMsg: 'Please choose ' + net + ' network in metamask wallet.'
          })
        }
      }
    })
  }

  getContract(abi, address) {
    if (address === undefined) return;
    console.log('getContract address', address)
    if (address.slice(0,3) === 'xdc') {
      address = '0x' + address.slice(3);
    }
    return new this.Web3Provider.eth.Contract(abi, address);
  }

  validateAddress(address) {
    console.log('validateAddress', address)
    if (address.slice(0,3) === 'xdc') {
      address = '0x' + address.slice(3);
    }
    return this.Web3Provider.utils.isAddress(address);
  }

  transferToOtherBlockchain = ({ userAddress, blockchain, amount, receiver, callback, currentToken }) => {
    const approveMethod = this.getMethodInterface('transferToOtherBlockchain', this.contractAbiSwap);
    let approveSignature;
    if (currentToken === 'Xinfin' && blockchain === 1) {
      approveSignature = this.encodeFunctionCall(approveMethod, [
        `0x${2}`,
        receiver,
      ]);
    }
    else {
      approveSignature = this.encodeFunctionCall(approveMethod, [
        `0x${5}`,
        new BigNumber(String(amount)).times(Math.pow(10, this.contractDecimals)).toString(10),
        receiver,
      ]);
    }
    let value;
    if (currentToken === 'Xinfin' && blockchain === 1) {
      value = (amount * 10**18).toString(16);
    }
    const approveTransaction = () => {
      return this.sendTransaction({
        from: userAddress,
        to: this.contractAddressSwap,
        data: approveSignature,
        value: (currentToken === 'Xinfin' && blockchain === 1) ? value : undefined,
      }, callback);
    };
    const transaction = {
      title: 'transferToOtherBlockchain',
      to: this.contractAddressSwap,
      data: approveSignature,
      action: approveTransaction,
      onComplete: callback
    };

    this.createTransactionObj(transaction, userAddress)
  }

  depositXdceWithSignature = ({amount, newAddress, signedAddress, signedAmounts, memorySignature, callback}) => {
    let newAmount = new BigNumber(String(amount)).multipliedBy(new BigNumber(10).pow(18)).toString();
    if (newAmount.includes('e')) {
      newAmount = (+newAmount).toLocaleString('fullwide', {useGrouping:false});
    }
    console.log('newAmount', newAmount);
    const approveMethod = this.getMethodInterface('depositWithSignature', this.contractAbiXDCESwap);
    const approveSignature = this.encodeFunctionCall(approveMethod, [
      newAmount,
      newAddress,
      signedAddress,
      signedAmounts,
      memorySignature
    ]);
    const approveTransaction = () => {
      return this.sendTransaction({
        from: signedAddress,
        to: this.contractAddressXDCESwap,
        data: approveSignature,
      }, callback);
    };
    const transaction = {
      title: 'depositWithSignature',
      to: this.contractAddressXDCESwap,
      data: approveSignature,
      action: approveTransaction,
      onComplete: callback
    };

    this.createTransactionObj(transaction, signedAddress);
  }

  depositXdce = ({amount, newAddress, signedAddress, callback}) => {
    let newAmount = new BigNumber(String(amount)).multipliedBy(new BigNumber(10).pow(18)).toString();
    if (newAmount.includes('e')) {
      newAmount = (+newAmount).toLocaleString('fullwide', {useGrouping:false});
    }
    console.log('newAmount', newAmount);
    const approveMethod = this.getMethodInterface('deposit', this.contractAbiXDCESwap);
    const approveSignature = this.encodeFunctionCall(approveMethod, [
      newAmount,
      newAddress,
    ]);
    const approveTransaction = () => {
      return this.sendTransaction({
        from: signedAddress,
        to: this.contractAddressXDCESwap,
        data: approveSignature,
      }, callback);
    };
    const transaction = {
      title: 'deposit',
      to: this.contractAddressXDCESwap,
      data: approveSignature,
      action: approveTransaction,
      onComplete: callback
    };

    this.createTransactionObj(transaction, signedAddress);
  }

  approveToken = (walletAddress, tokenAddress, amount, callback, currentToken) => {
    let approveMethod;
    if (currentToken === 'XDCE') {
      approveMethod = this.getMethodInterface('approve', this.contractAbiXDCEToken);
    }
    if (currentToken === 'Wish' || currentToken === 'Xinfin') {
      approveMethod = this.getMethodInterface('approve', this.contractAbiToken);
    }
    const approveSignature = this.encodeFunctionCall(approveMethod, [
      tokenAddress,
      new BigNumber(String(amount)).times(Math.pow(10, this.contractDecimals)).toString(10),
    ]);
    const approveTransaction = () => {
      return this.sendTransaction({
        from: walletAddress,
        to: currentToken === 'XDCE' ? this.contractAddressXDCEToken : this.contractAddressToken,
        data: approveSignature,
      }, callback);
    };
    const transaction = {
      title: 'Authorise the contract, approving tokens',
      to: currentToken === 'XDCE' ? this.contractAddressXDCEToken : this.contractAddressToken,
      data: approveSignature,
      action: approveTransaction,
      onComplete: callback
    };
    this.createTransactionObj(transaction, walletAddress)
  }


  sendTx = async (methodName, addressFrom, data, amount) => {
    try {
      const method = this.getMethodInterface(methodName, this.contractAbiToken); //TODO:contractAbiToken
      const signature = this.encodeFunctionCall(method, data);
      const params = {
        from: addressFrom,
        to: this.contractAddressToken,
        value: amount,
        data: signature,
      };
      const txHash = await this.wallet.request({
        method: 'eth_sendTransaction',
        params: [params],
      });
      const txReceipt = new Promise((resolve, reject) => {
        const trxSubscription = setInterval(() => {
          this.Web3Provider.eth.getTransactionReceipt(
          txHash,
          (error, transaction) => {
            if (transaction) {
              if (transaction.status) {
                resolve(transaction);
              } else {
                reject(error);
              }
              clearInterval(trxSubscription);
            }
            if (error) {
              clearInterval(trxSubscription);
            }
          },
          );
        }, 1000);
      })
      return await txReceipt;
    } catch (e) {
      console.error(e);
    }
  }

  encodeFunctionCall(abi, data) {
    return this.Web3Provider.eth.abi.encodeFunctionCall(abi, data);
  }

  getMethodInterface(methodName, abi) {
    return abi.filter((m) => {
      return m.name === methodName;
    })[0];
  }

  prepareTransaction(wallet, transaction) {
    transaction.action(wallet)
  }

  createTransactionObj(transaction, walletAddress) {
    this.prepareTransaction({
      type: 'metamask',
      address: walletAddress,
    }, transaction);
  }

  sendTransaction(transactionConfig, callback, errCallback) {
    console.log('transactionConfig', transactionConfig);
    this.wallet.request({
      method: 'eth_sendTransaction',
      params: [transactionConfig]
    }).then(() => {
      if (callback) {
        setTimeout(() => callback(true), 5000)
      }
    }).catch((error) => {
      console.log(error, 'error')
      callback(false)
    });
  }


}
