import React from 'react';

import './style.scss';
import { ReactComponent as IconArrow } from "../../assets/icons/arrow-down.svg";

function Dropdown(props) {
  let {
    label = 'Choose',
    items = [],
    onChange = () => {},
    value = null,
    disabled = false,
  } = props;

  const ref = React.useRef();

  const [open, setOpen] = React.useState(false);

  const choosenItem = (items && value) ?
  items.filter((item) => item.key===value)[0] : null;

  items = items && items.filter((item) => item.key!==value)

  const handleChoose = (key) => {
    onChange(key)
  }

  const handleClickOutside = (e) => {
    if (e.target !== ref.current) setOpen(false)
  }

  React.useEffect(() => {
    document.addEventListener('click',handleClickOutside)
  },[])

  React.useEffect(() => {
    document.addEventListener('click',handleClickOutside)
  },[items, value])

  return (
    <div className={`${ disabled ? 'dropdown-disabled' : 'dropdown'}`}>
      <div
      ref={ref}
      className="dropdown-label"
      onClick={() => setOpen(!open)}
      >
        <div className="dropdown-label-left">
          {choosenItem &&
          <img
          className="dropdown-item-image"
          src={choosenItem.image}
          />
          }
          { choosenItem ? choosenItem.text : label }
        </div>

        { items && items.length > 0 &&
        <IconArrow
        className={ open ? 'dropdown-arrow-right' : 'dropdown-arrow-down' }
        />
        }

      </div>

      {/*{ items && items.length>1 &&*/}
      <div className={open ? "dropdown-list-open" : "dropdown-list"}>
        { open && items.map((item,ii) => {
          const { key='', text='', image } = item;
          return (
          <div
          key={`${key}-${ii}`}
          className="dropdown-item"
          onClick={ () => handleChoose(item.key) }
          >
            <img
            className="dropdown-item-image"
            src={ image }
            />
            { text }
          </div>
          )
        })}
      </div>
      {/*}*/}
    </div>
  );
}

export default Dropdown;
