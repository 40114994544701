import Web3 from 'web3';
import BigNumber from "bignumber.js";
import config from '../../config';

export default class BinanceService {
  constructor({ networkFrom, contractDetails }) {
    this.name = 'binance'
    this.wallet = window['BinanceChain']
    this.networkFrom = networkFrom
    this.net = config.IS_PRODUCTION ? 'mainnet' : 'testnet'
    this.nets = {
      mainnet: {
        'Binance-Chain': {
          name: 'Binance chain',
          code: 'Binance-Chain-Tigris',
        },
        'Binance-Smart-Chain': {
          name: 'Binance smart chain',
          code: '0x38',
        },
      },
      testnet: {
        'Binance-Chain': {
          name: 'Binance chain testnet',
          code: 'Binance-Chain-Ganges',
        },
        'Binance-Smart-Chain': {
          name: 'Binance smart chain testnet',
          code: '0x61',
        },
      }
    }
    // this.providerName = config.IS_PRODUCTION ? 'https://bsc-dataseed.binance.org/' : 'https://data-seed-prebsc-1-s1.binance.org:8545'
    this.Web3Provider = new Web3(this.wallet);
    this.wallet && this.wallet.on('chainChanged', () => window.location.reload());
    this.wallet && this.wallet.on('accountsChanged', () => window.location.reload());
    this.contractAddressToken = contractDetails.ADDRESS.TOKEN[networkFrom];
    this.contractAddressSwap = contractDetails.ADDRESS.SWAP[networkFrom];
    this.contractAbiToken = contractDetails.ABI.TOKEN[networkFrom];
    this.contractAbiSwap = contractDetails.ABI.SWAP[networkFrom];
    this.contractDecimals = contractDetails.DECIMALS.TOKEN[networkFrom];
  }

  getAccount() {
    if (!this.wallet) throw new Error(`${this.name} wallet is not injected`);
    return new Promise((resolve, reject) => {
      const netType = config.IS_PRODUCTION ? 'mainnet' : 'testnet'
      // console.log('getAccount netVersion', netType)
      const netCode = this.nets[netType][this.networkFrom].code
      // const netCode = config.IS_PRODUCTION ? '0x38' : '0x61'
      const netVersion = this.wallet.chainId
      // console.log('getAccount netVersion', this.networkFrom, netVersion)
      if (netVersion === netCode) {
        this.wallet.request({ method: 'eth_requestAccounts' }).then(account => {
          resolve({
            address: account[0],
            network: netVersion,
          })
        })
        .catch(_ => reject({ errorMsg: 'Not authorized' }))
      } else {
        reject({
          errorMsg: `Please choose ${this.networkFrom} network in ${this.name} wallet.`
        })
      }
    })
  }

  getContract(abi, address) {
    return new this.Web3Provider.eth.Contract(abi, address);
  }

  approveToken = (walletAddress, tokenAddress, amount, callback,) => {
    const approveMethod = this.getMethodInterface('approve', this.contractAbiToken);
    const approveSignature = this.encodeFunctionCall(approveMethod, [
      tokenAddress,
      new BigNumber(amount).times(Math.pow(10, this.contractDecimals)).toString(10),
    ]);
    const approveTransaction = () => {
      return this.sendTransaction({
        from: walletAddress,
        to: this.contractAddressToken,
        data: approveSignature,
      }, callback);
    };
    const transaction = {
      title: 'Authorise the contract, approving tokens',
      to: this.contractAddressToken,
      data: approveSignature,
      action: approveTransaction,
      onComplete: callback
    };
    this.createTransactionObj(transaction, walletAddress)
  }

  transferToOtherBlockchain = ({ userAddress, blockchain, amount, receiver, callback }) => {
    const approveMethod = this.getMethodInterface('transferToOtherBlockchain', this.contractAbiSwap);
    const approveSignature = this.encodeFunctionCall(approveMethod, [
      `0x${blockchain}`,
      new BigNumber(+amount).times(Math.pow(10, this.contractDecimals)).toString(10),
      receiver,
    ]);
    const approveTransaction = () => {
      return this.sendTransaction({
        from: userAddress,
        to: this.contractAddressSwap,
        data: approveSignature,
      }, callback);
    };
    const transaction = {
      title: 'transferToOtherBlockchain',
      to: this.contractAddressSwap,
      data: approveSignature,
      action: approveTransaction,
      onComplete: callback
    };
    this.createTransactionObj(transaction, userAddress)
  }

  sendPlainTx = async ({ addressFrom, amount, data } ) => {
    try {
      const accounts = await this.wallet.requestAccounts()
      const txHash = await this.wallet.transfer({
        fromAddress: addressFrom,
        toAddress: this.contractAddressSwap,
        asset: config.IS_PRODUCTION ? "WISH-2D5" : "WISHTST2-2BD", //TODO: from API
        amount,
        accountId: accounts[0].id,
        networkId: config.IS_PRODUCTION ? "bbc-mainnet" : "bbc-testnet",
        memo: data,
      })
      const txReceipt = new Promise((resolve, reject) => {
        const trxSubscription = setInterval(() => {
          this.Web3Provider.eth.getTransactionReceipt(
          txHash,
          (error, transaction) => {
            if (transaction) {
              if (transaction.status) {
                resolve(transaction);
              } else {
                reject(error);
              }
              clearInterval(trxSubscription);
            }
            if (error) {
              clearInterval(trxSubscription);
            }
          },
          );
        }, 1000);
      })
      return await txReceipt;
    } catch (e) {
      console.error(e);
    }
  }

  sendTx = async (methodName, addressFrom, data, amount) => {
    try {
      const method = this.getMethodInterface(methodName, this.contractAbiSwap);
      const signature = this.encodeFunctionCall(method, data);
      const params = {
        from: addressFrom,
        to: this.contractAddressSwap,
        value: amount,
        data: signature,
      };
      const txHash = await this.wallet.request({
        method: 'eth_sendTransaction',
        params: [params],
      })
      const txReceipt = new Promise((resolve, reject) => {
        const trxSubscription = setInterval(() => {
          this.Web3Provider.eth.getTransactionReceipt(
          txHash,
          (error, transaction) => {
            if (transaction) {
              if (transaction.status) {
                resolve(transaction);
              } else {
                reject(error);
              }
              clearInterval(trxSubscription);
            }
            if (error) {
              clearInterval(trxSubscription);
            }
          },
          );
        }, 1000);
      })
      return await txReceipt;
    } catch (e) {
      console.error(e);
    }
  }

  encodeFunctionCall(abi, data) {
    return this.Web3Provider.eth.abi.encodeFunctionCall(abi, data);
  }

  getMethodInterface(methodName, abi) {
    return abi.filter((m) => {
      return m.name === methodName;
    })[0];
  }

  prepareTransaction(wallet, transaction) {
    transaction.action(wallet)
  }

  createTransactionObj(transaction, walletAddress) {
    this.prepareTransaction({
      type: 'metamask',
      address: walletAddress,
    }, transaction);
  }

  sendTransaction(transactionConfig, callback, errCallback) {
    this.wallet.request({
      method: 'eth_sendTransaction',
      params: [transactionConfig]
    }).then(() => {
      if (callback) {
        setTimeout(() => callback(true), 5000)
      }
    }).catch((error) => {
      console.log(error, 'error')
      callback(false)
    });
  }


}
