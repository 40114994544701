import { getFromStorage } from '../../utils';

let defaultCurrentToken = getFromStorage('defaultCurrentToken');
if (!defaultCurrentToken) {
    defaultCurrentToken = 'Wish'
}
const initialState = {
    name: defaultCurrentToken
}

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case 'TOKEN:SET_NAME':
            return {
                ...state,
                ...payload
            };
        default:
            return state
    }
}
