import React, {useState} from 'react';
import { useDispatch, useSelector } from "react-redux";
import Web3 from 'web3';
import { XdcConnect, Disconnect, SendTransaction, GetWallet } from "xdc-connect";

import config from '../../config';
import './style.scss';
import Dropdown from "../Dropdown";
import Input from "../Input";
import ModalManualSwapContent from "../ModalManualSwapContent";
import { ReactComponent as IconArrowWhite } from "../../assets/icons/arrow-right-white.svg";
import { ReactComponent as IconError } from "../../assets/icons/error.svg";
import { ReactComponent as IconInfo } from "../../assets/icons/info.svg";
import { ReactComponent as IconLink } from "../../assets/icons/link.svg";
import {userActions, modalActions, walletActions, formActions, tokenActions, pendingActions} from '../../redux/actions';
import { useContractContext } from '../../contexts/contractContext';
import {BackendService, setToStorage, getTokenSymbol, getTokenLink, getFromStorage} from '../../utils';
import ClipLoader from "react-spinners/ClipLoader";
import BigNumber from "bignumber.js";
import {useDebounce} from "use-debounce";

const backendService = new BackendService();
const web3 = new Web3(window.ethereum);

const currentTokens = [
  {id:0, key: 'Wish', text: 'WISH', image: require('../../assets/icons/crypto/wish-circle.svg').default},
  {id:1, key: 'Xinfin', text: 'XDC', image: require('../../assets/icons/crypto/xdc-circle.svg').default},
  {id:3, key: 'XDCE', text: 'XDCE', image: require('../../assets/icons/crypto/xdc-circle.svg').default}
]

const networksXDC = [
  {id:5, key: 'Xinfin', text: 'Xinfin', image: require('../../assets/icons/crypto/xdc-circle.svg').default},
  {id:2, key: 'Ethereum', text: 'Ethereum', image: require('../../assets/icons/crypto/eth-circle.svg').default}
]

const networksXDCEFrom = [
  {id:2, key: 'Ethereum', text: 'Ethereum', image: require('../../assets/icons/crypto/eth-circle.svg').default}
]

const networksXDCETo = [
  {id:5, key: 'Xinfin', text: 'Xinfin', image: require('../../assets/icons/crypto/xdc-circle.svg').default}
]

const networksXDCFrom = [
  {id:5, key: 'Xinfin', text: 'Xinfin', image: require('../../assets/icons/crypto/xdc-circle.svg').default},
  {id:2, key: 'Ethereum', text: 'Ethereum', image: require('../../assets/icons/crypto/eth-circle.svg').default}
]

const networksXDCTo = [
  {id:5, key: 'Xinfin', text: 'Xinfin', image: require('../../assets/icons/crypto/xdc-circle.svg').default},
  {id:2, key: 'Ethereum', text: 'Ethereum', image: require('../../assets/icons/crypto/eth-circle.svg').default}
]

const networksMetamask = [
  {id:2, key: 'Ethereum', text: 'Ethereum', image: require('../../assets/icons/crypto/eth-circle.svg').default},
  {id:0, key: 'Binance-Chain', text: 'Binance-Chain', image: require('../../assets/icons/crypto/bnb-circle.svg').default},
  {id:1, key: 'Binance-Smart-Chain', text: 'Binance-Smart-Chain', image: require('../../assets/icons/crypto/bnb-circle.svg').default},
]

const networksMetamaskFrom = [
  {id:2, key: 'Ethereum', text: 'Ethereum', image: require('../../assets/icons/crypto/eth-circle.svg').default},
  {id:0, key: 'Binance-Chain', text: 'Binance-Chain', image: require('../../assets/icons/crypto/bnb-circle.svg').default},
  {id:1, key: 'Binance-Smart-Chain', text: 'Binance-Smart-Chain', image: require('../../assets/icons/crypto/bnb-circle.svg').default},
]

const networksMetamaskTo = [
  {id:2, key: 'Ethereum', text: 'Ethereum', image: require('../../assets/icons/crypto/eth-circle.svg').default},
  {id:0, key: 'Binance-Chain', text: 'Binance-Chain', image: require('../../assets/icons/crypto/bnb-circle.svg').default},
  {id:1, key: 'Binance-Smart-Chain', text: 'Binance-Smart-Chain', image: require('../../assets/icons/crypto/bnb-circle.svg').default},
]


function Form() {
  const { walletService, contractService } = useContractContext();

  const dispatch = useDispatch();
  const { address: userAddress, } = useSelector(({ user }) => user);
  const form = useSelector(({ form }) => form);
  const wallet = useSelector(({ wallet }) => wallet);
  const { dexList } = useSelector(({ wallet }) => wallet);
  const { isPending } = useSelector(({ pending }) => pending);

  const [networks, setNetworks] = React.useState(networksMetamask);
  const [networksFrom, setNetworksFrom] = React.useState(networksMetamaskFrom);
  const [networksTo, setNetworksTo] = React.useState(networksMetamaskTo);
  const [networkFrom, setNetworkFrom] = React.useState(wallet.networkFrom);
  const [networkTo, setNetworkTo] = React.useState('Ethereum');
  const [currentToken, setCurrentToken] = React.useState(getFromStorage('defaultCurrentToken') || 'Wish');
  const [amount, setAmount] = React.useState('0');
  const [receive, setReceive] = React.useState('0');
  const [receiver, setReceiver] = React.useState('');
  const [memo, setMemo] = React.useState('');
  const [fee, setFee] = React.useState(0);
  const [minimumAmount, setMinimumAmount] = React.useState('');
  const [maximumAmount, setMaximumAmount] = React.useState('');
  const [conractAddressSwap, setConractAddressSwap] = React.useState();
  const [tokenAddressFrom, setTokenAddressFrom] = React.useState();
  const [tokenAddressTo, setTokenAddressTo] = React.useState();
  const [approved, setApproved] = React.useState(false);
  const [approving, setApproving] = React.useState(false);
  const [xinpayWallet, setXinpayWallet] = useState();
  const [xdcSignature, setXdcSignature] = useState();
  const [xdcAmount, setXdcAmount] = useState();
  const [maximumXDCEAmount, setMaximunXDCEAmount] = useState();

  const [debouncedValue] = useDebounce(amount, 1000);

  const isNetworkFromBinanceChain = networkFrom === 'Binance-Chain';
  const isNetworkFromBinanceSmartChain = networkFrom === 'Binance-Smart-Chain';
  const isNetworkFromEthereum = networkFrom === 'Ethereum';
  const isNetworkToBinanceChain = networkTo === 'Binance-Chain';
  const isNetworkToBinanceSmartChain = networkTo === 'Binance-Smart-Chain';
  const isNetworkToEthereum = networkTo === 'Ethereum';

  const isNetworkFromXinfin = networkFrom === 'Xinfin';
  const isNetworkToXinfin = networkTo === 'Xinfin';

  const isCurrentTokenWish = currentToken === 'Wish';
  const isCurrentTokenXinfin = currentToken === 'Xinfin';

  const toggleModal = ({isOpen, text}) => dispatch(modalActions.toggleModal({ isOpen, text }))
  const showFormError = (data) => dispatch(formActions.showFormError(data));

  const setDefaultProperties = async () => {
    if (getFromStorage('defaultCurrentToken')) {
      const newCurrentToken = getFromStorage('defaultCurrentToken');
      switch(newCurrentToken) {
        case 'Xinfin':
          setNetworks(networksXDC);
          setNetworkFrom('Xinfin');
          setNetworkTo('Ethereum');
          setNetworksFrom(networksXDCFrom);
          setNetworksTo(networksXDCTo);
          dispatch(walletActions.setWalletNetFrom('Xinfin'))
          break;
        case 'Wish':
          setNetworks(networksMetamask);
          setNetworkFrom('Ethereum');
          setNetworkTo('Binance-Chain');
          setNetworksFrom(networksMetamaskFrom);
          setNetworksTo(networksMetamaskTo);
          dispatch(walletActions.setWalletNetFrom('Ethereum'))
          break;
        case 'XDCE':
          setNetworks(networksXDC);
          setNetworkFrom('Ethereum');
          setNetworkTo('Xinfin');
          setNetworksFrom(networksXDCEFrom);
          setNetworksTo(networksXDCETo);
          dispatch(walletActions.setWalletNetFrom('Ethereum'))
          // let resultGetMaximumXDCEAmount = await backendService.getMaximumXDCEAmount();
          // resultGetMaximumXDCEAmount = new BigNumber(resultGetMaximumXDCEAmount.data.xdceSwapLimit).dividedBy(10**18).toString();
          // setMaximunXDCEAmount(resultGetMaximumXDCEAmount);
          // toggleModal({
          //       isOpen: true,
          //       text:(
          //           <div>
          //             You can send max <b>{ resultGetMaximumXDCEAmount }</b> XDCE<br/>
          //           </div>
          //       )
          //     });
          break;
      }
    }
  }

  const getFee = () => {
    const network = networks.filter((item) => item.key===networkTo)[0];
    const networkName = network && network.text;
    console.log('dexList', dexList);
    const dex = dexList && dexList.filter((item) => item.network===networkName)[0];
    const fee = dexList ? dex && dex.fee : 0;
    setFee(fee);
    return fee;
  }

  const getMinimumAmount = () => {
    try {
      let minimumAmount;
      if (currentToken === 'XDCE') {
        minimumAmount = dexList.filter(token => token.network === networkFrom)[0]?.xdce_min_amount;
      } else {
        minimumAmount = dexList.filter(token => token.network === networkFrom)[0]?.min_amount;
      }


      setMinimumAmount(minimumAmount);
      return minimumAmount;
    } catch (e) {
      console.error(e);
    }
  };

  const getMaximumAmountXDCE = () => {
    try {
      const newMaximumAmount = dexList.filter(token => token.network === networkFrom && currentToken === 'XDCE')[0]?.xdce_max_amount;
      setMaximumAmount(newMaximumAmount);
      return newMaximumAmount;
    } catch (e) {
      console.error(e);
    }
  };

  const getAddresses = () => {
    if (!dexList) return;
    const dexFrom = dexList.filter((item) => item.network===networkFrom)[0];
    const dexTo = dexList.filter((item) => item.network===networkTo)[0];

    if(!dexFrom || !dexTo) {
      console.log('dexFrom or dexTo is undefined');
      return;
    }

    // console.log('getAddresses',dexFrom,dexTo)
    setTokenAddressFrom(dexFrom.token_address);
    setTokenAddressTo(dexTo.token_address);
  }

  const validateAddress = (address) => {
    if (networkTo==='Binance-Chain') {
      const regMainnet = /bnb1[0-9a-zA-Z]{38}/gi;
      const regTestnet = /tbnb1[0-9a-zA-Z]{37}/gi;
      const reg = config.IS_PRODUCTION ? regMainnet : regTestnet;
      if (!reg.test(address) || (address.trim() === '') || (address.trim().length < 42))
        return false;
      else return true;
    } else {
      const web3 = new Web3();
      return web3.utils.isAddress(address);
    }
  }

  const amountDebounce = async (value) => {
    showFormError({ amount: null });
    const network = networks.filter((item) => item.key===networkFrom)[0];
    const networkName = network && network.text;
    const dex = dexList && dexList.filter((item) => item.network===networkName)[0];
    let newValue = value;
    if (newValue < 0) newValue = "0";
    if (
        newValue.length > 1 &&
        Number(newValue) >= 1 &&
        newValue.slice(0, 1)[0] === "0"
    )
      newValue = newValue.slice(1);
    newValue = String(newValue).replace(",", ".");
    if (!dex) newValue = "0";
    setAmount(newValue);
  }

  const sendDebounceRequest = async (value) => {
    if (!userAddress) return;
    let newValue = value;
    if (newValue < 0) newValue = '0';
    if (newValue.length > 1 && Number(newValue) >= 1 && newValue.slice(0,1)[0]==='0') newValue = newValue.slice(1);
    newValue = String(newValue).replace(',','.');
    // get swap address
    if (!dexList) newValue = '0';
    const network = networks.filter((item) => item.key===networkFrom)[0];
    const networkName = network && network.text;
    const dex = dexList && dexList.filter((item) => item.network===networkName)[0];

    if (!dex) return setReceive('0');

    setAmount(newValue);
    let resultGetMaximumXDCEAmount = await backendService.getMaximumXDCEAmount(userAddress);
    resultGetMaximumXDCEAmount = new BigNumber(resultGetMaximumXDCEAmount.data.xdceSwapLimit).dividedBy(10**18).toString();
    setMaximunXDCEAmount(resultGetMaximumXDCEAmount);

    let resultGetMaximumXDCAmount = await backendService.getMaximumXDCAmount(userAddress, amount);
    resultGetMaximumXDCAmount = new BigNumber(resultGetMaximumXDCAmount?.data.xdcAmount).dividedBy(new BigNumber(10).pow(18)).toString();
    setReceive(resultGetMaximumXDCAmount);
    setConractAddressSwap(dex.swap_address)
  }

  const handleChangeAmount = (value) => {
    try {
      showFormError({ amount:null });
      if (currentToken !== 'XDCE') {
        let newValue = value;
        if (newValue < 0) newValue = '0';
        if (newValue.length > 1 && Number(newValue) >= 1 && newValue.slice(0,1)[0]==='0') newValue = newValue.slice(1);
        newValue = String(newValue).replace(',','.');
        // get swap address
        if (!dexList) newValue = '0';
        const network = networks.filter((item) => item.key===networkFrom)[0];
        const networkName = network && network.text;
        const dex = dexList && dexList.filter((item) => item.network===networkName)[0];
        // console.log(networks,networkFrom,networkName,wallet,dex)
        if (!dex) return setReceive('0');
        const fee = getFee();
        let newReceive = (+newValue*10**6 - +fee*10**6)/10**6;
        if (newReceive < 0) newReceive = '0';
        if (isNaN(newReceive)) newReceive = '0';
        setReceive(newReceive);
        setAmount(newValue);
        setConractAddressSwap(dex.swap_address)
      }
    } catch (e) {
      console.error(e);
    }
  }

  React.useEffect(() => {
    if(debouncedValue === amount && currentToken === 'XDCE'){
      sendDebounceRequest(amount);
    }
  }, [sendDebounceRequest]);

  const handleSendMax = async () => {
    try {
      console.log('handleSendMax');
      let balance;
      console.log(currentToken);
      if (currentToken === 'Xinfin' && networkFrom === 'Xinfin') {
        balance = await web3.eth.getBalance(userAddress);
        balance = new BigNumber(String(balance)).dividedBy(10**18);
      }
      if (currentToken === 'Wish' || (currentToken === 'Xinfin' && networkFrom === 'Ethereum')) {
        balance = await contractService.balanceOf(userAddress);
      }
      if (currentToken === 'XDCE') {
        showFormError({amount: null})
        const balance = await contractService.balanceOf(userAddress, currentToken);
        if (+new BigNumber(String(maximumXDCEAmount)).comparedTo(new BigNumber(String(balance))).toString() > 0) {
          sendDebounceRequest(new BigNumber(balance)).toString();
          return;
        }
        sendDebounceRequest(maximumXDCEAmount);
        return;
      }


      console.log(balance)
      handleChangeAmount(balance)
    } catch (e) {
      console.error(e);
    }
  }

  const handleFocusAmount = (e) => {
    let newValue = e.target.value;
    if (newValue==='0') setAmount('')
  }

  const handleChangeNetworkFrom = (e) => {
    try {
      console.log('handleChangeNetworkFrom', e);
      dispatch(walletActions.setWalletType(null))
      dispatch(userActions.setUserData({address:null}))
      setNetworkFrom(e)
      setToStorage('defaultNetworkFrom',e)
      dispatch(walletActions.setWalletNetFrom(e))
    } catch (e) {
      console.error(e);
    }
  }

  const handleChangeCurrentToken = async (e) => {
    try {
      Disconnect();
      setXinpayWallet(null);
      showFormError({ receiver: null, amount: null})
      setCurrentToken(e);
      setToStorage('defaultCurrentToken', e);
      dispatch(walletActions.setWalletType(null));
      dispatch(userActions.setUserData({address:null}));
      dispatch(tokenActions.setCurrentToken({name: e}));
      dispatch(walletActions.setWalletType(null));
      setReceive(0);
      setAmount(0);
      switch (e) {
        case 'Xinfin':
          setNetworks(networksXDC);
          setNetworkFrom(e);
          setNetworkTo('Ethereum');
          setNetworksFrom(networksXDCFrom);
          setNetworksTo(networksXDCTo);
          dispatch(userActions.setUserData({address:null}));
          dispatch(walletActions.setWalletNetFrom('Xinfin'))
          break;
        case 'Wish':
          setNetworks(networksMetamask);
          setNetworkFrom('Ethereum');
          setNetworkTo('Binance-Chain');
          setNetworksFrom(networksMetamaskFrom);
          setNetworksTo(networksMetamaskTo);
          dispatch(walletActions.setWalletNetFrom('Ethereum'))
          break;
        case 'XDCE':
          setNetworks(networksXDC);
          setNetworkFrom('Ethereum');
          setNetworkTo('Xinfin');
          setNetworksFrom(networksXDCEFrom);
          setNetworksTo(networksXDCETo);
          dispatch(userActions.setUserData({address:null}));
          dispatch(walletActions.setWalletNetFrom('Ethereum'));
          break;
      }
    } catch (e) {
      console.log(e);
    }
  }

  const getNetworksTo = () => {
    const networksTo = networks.filter((item) => item.key !== networkFrom)
    setNetworksTo(networksTo)
    if (currentToken === 'Wish'){
      if (networkFrom===networkTo) handleChangeNetworkTo(null)
      if (networkFrom==='Ethereum') handleChangeNetworkTo('Binance-Chain')
      if (networkFrom==='Binance-Chain') handleChangeNetworkTo('Ethereum')
      if (networkFrom==='Binance-Smart-Chain') handleChangeNetworkTo('Binance-Chain')
    }
    if (currentToken === 'Xinfin'){
      if (networkFrom==='Ethereum') handleChangeNetworkTo('Xinfin')
      if (networkFrom==='Xinfin') handleChangeNetworkTo('Ethereum')
    }

  }

  const handleChangeNetworkTo = (e) => {
    try {
      showFormError({ receiver: null });
      setNetworkTo(e)
      if (!receiver) return
      const network = networks && networks.filter((item) => item.key===e)[0]
      setMemo(network.id+receiver)
    } catch (e) {
      console.error(e);
    }
  }

  const handleChangeReceiver = async (e) => {
    try {
      showFormError({ receiver: null });
      setReceiver(e)
      if (!e) return
      const network = networks && networks.filter((item) => item.key===networkTo)[0]
      setMemo(network.id+e)
    } catch (e) {
      console.error(e);
    }
  }

  const checkGas = async (swap) => {
    try {
      const resultGetGas = await backendService.getGas({network:networkTo})
      const gas = resultGetGas.data;
      console.log('resultGetGas',gas)
      if (gas && (gas.status !== 'OK')) {
        toggleModal({
          isOpen: true,
          text:(
          <div>
            <div className="modal-gas-text">
              <div>
                Gas price in the { networkTo.split('-').join(' ') } network is too high.
              </div>
              <div>
                The swap can take longer than usual.
              </div>
            </div>
            <div className="modal-gas-buttons">
              <div
              className="button mh10 mt20"
              onClick={cancelSwap}
              >
                <IconArrowWhite className="button-arrow"/>
                Close
              </div>
              <div
              className="button mh10 mt20"
              onClick={swap}
              >
                <IconArrowWhite className="button-arrow"/>
                Proceed
              </div>
            </div>
          </div>
          )
        });
        return false;
      } else {
        return true;
      }
    } catch (e) {
      console.error(e);
    }
  }

  const checkMinimumAmount = (amount) => {
      return (new BigNumber(amount).comparedTo(new BigNumber(minimumAmount)).toString() < 0)
  }

  const checkMaximumAmount = async (amount) => {
    if (currentToken === 'XDCE') {
      let newMaximunXDCEAmount;
      const balance = await contractService.balanceOf(userAddress, currentToken);
      newMaximunXDCEAmount = BigNumber.minimum(new BigNumber(maximumXDCEAmount), new BigNumber(balance), new BigNumber(maximumAmount)).toString();
      console.log('maximumXDCEAmount', new BigNumber(maximumXDCEAmount).toString());
      console.log('balance', new BigNumber(balance).toString());
      console.log('maximumAmount', new BigNumber(maximumAmount).toString());
      console.log('newMaximumAmount', new BigNumber(newMaximunXDCEAmount).toString());
      return {res: (+new BigNumber(amount).comparedTo(new BigNumber(newMaximunXDCEAmount)).toString() > 0 || +newMaximunXDCEAmount === 0), value: newMaximunXDCEAmount}
    } else return false;
  }

  const checkFields = async () => {
    try {
      let newReceiver = receiver;
      if (receiver.slice(0,3) === 'xdc') {
        newReceiver = '0x' + newReceiver.slice(3);
      }
      let isAddressValid = validateAddress(newReceiver);
      if (!approved) {
        isAddressValid = true;
      }
      const isAmountNaN = isNaN(+amount);
      const isAmountLessThanMinimum = checkMinimumAmount(amount);
      const resultIsAmountMoreThanMaximum = await checkMaximumAmount(amount);
      const isAmountMoreThanMaximum = resultIsAmountMoreThanMaximum.res;
      if (resultIsAmountMoreThanMaximum.res) {
        toggleModal({
          isOpen: true,
          text:(
              <div>
                The maximum amount is <b>{ resultIsAmountMoreThanMaximum.value }</b> XDCE<br/>
              </div>
          )
        });
        showFormError({ amount:{ text: `Amount is more than maximum`, } });
      }
      if (isAmountNaN) showFormError({ amount:{ text: `Amount is not valid`, } });
      if (isAmountLessThanMinimum){
        toggleModal({
          isOpen: true,
          text:(
              <div>
                The minimum amount is <b>{ minimumAmount }</b><br/>
              </div>
          )
        });
        showFormError({ amount: { text: `The minimum amount is ${minimumAmount}` } });
      }
      if (!isAddressValid) {
        showFormError({ receiver: { text: `Destination address is not valid` } });
        return false;
      }
      if (isAmountLessThanMinimum || isAmountNaN || isAmountMoreThanMaximum) return false; // чтобы сразу все предупреждения выскочили
      return true;
    } catch (e) {
      console.error(e);
    }
  }

  const checkAllowance = async (intervalCheckAllowance) => {
    try {
      let balance;
      let allowance;
      if (currentToken === 'Xinfin' && networkFrom === 'Xinfin') {
        let balanceNative = await web3.eth.getBalance(userAddress);
        balance = +balanceNative;
        return true;
      }
      else {
        balance = await contractService.balanceOf(userAddress)
        allowance = await contractService.allowance(userAddress,currentToken)
        console.log('allowance',allowance)

        if (allowance > 0 && amount <= allowance) {
          setApproving(false)
          setApproved(true);
          clearInterval(intervalCheckAllowance);
          return true;
      }


      };
      return false;
    } catch (e) {
      clearInterval(intervalCheckAllowance);
      console.error(e);
    }
  }

  const approve = async () => {
    try {
      console.log('currentToken', currentToken);
      const areAllFieldsOk = await checkFields();
      if (!areAllFieldsOk) return;
      setApproving(true)
      await contractService.approveToken(userAddress, async (res) => {
        console.log('approveToken', res)
        if (!res) return setApproving(false);
        const intervalCheckAllowance = setInterval(async () => {
          await checkAllowance(intervalCheckAllowance)
        },500)
      }, currentToken)
    } catch (e) {
      console.error(e);
    }
  }

  const cancelSwap = async () => {
    return toggleModal({ isOpen:false, text: null });
  }

  const swap = async () => {
    try {
      toggleModal({ isOpen: false, text: null, });
      const areAllFieldsOk = await checkFields();
      if (!areAllFieldsOk) return;

      let resultGetXDCSignature;
      if (currentToken === 'XDCE' && userAddress) {
        resultGetXDCSignature = await backendService.getXDCSignature(userAddress);
        setXdcSignature(resultGetXDCSignature?.data);
      }


      if (isNetworkFromBinanceChain) {
        const blockchain = networks && networks.filter((item) => item.key===networkTo)[0].id
        const balance = await contractService.transferFromBinanceChain({
          userAddress, blockchain, amount, receiver,
        })
        console.log(balance)
        return;
      }
      const blockchain = networks && networks.filter((item) => item.key===networkTo)[0].id;
      if (currentToken === 'XDCE') {
        const signedAddress = resultGetXDCSignature?.data.address;
        const signedAmounts = resultGetXDCSignature?.data.amounts;
        const memorySignature = resultGetXDCSignature?.data.signature;
        const newAddress = receiver;
        const resultIsXDCSigned = await backendService.isXDCSigned(userAddress);
        if (!resultIsXDCSigned.data){
          await contractService.depositXdceWithSignature({
            amount,
            newAddress,
            signedAddress,
            signedAmounts,
            memorySignature,
            callback: async (res) => console.log('depositXdceWithSignature',res), currentToken,
          });
        } else {
          await contractService.depositXdce({
            amount,
            newAddress,
            signedAddress,
            callback: async (res) => console.log('depositXdceWithSignature',res), currentToken,
          });
        }


        return;
      }
      await contractService.transferToOtherBlockchain({
        userAddress, blockchain, amount, receiver,
        callback: async (res) => console.log('transferToOtherBlockchain',res), currentToken,
      })
    } catch (e) {
      console.error(e);
    }
  }

  const swapManual = async () => {
    toggleModal({ isOpen:false, text:null, });
    const areAllFieldsOk = checkFields();
    if (!areAllFieldsOk) return;
    toggleModal({
      isOpen:true,
      text:
      <ModalManualSwapContent
      receiver={receiver}
      conractAddressSwap={conractAddressSwap}
      amount={amount}
      memo={memo}
      networkFrom={networkFrom}
      networkTo={networkTo}
      />
    });
  }

  const handleSwapManual = async () => {
    try {
      if (isNetworkToBinanceChain) return swapManual();
      const isGasOk = await checkGas(swapManual)
      if (isGasOk) swapManual()
    } catch (e) {
      console.error(e);
    }
  }

  const handleSwap = async () => {
    try {
      if (isNetworkToBinanceChain) return swap();
      const isGasOk = await checkGas(swap)
      if (isGasOk) swap()
    } catch (e) {
      console.error(e);
    }
  }

  const handleDisconnectWalletConnect = async () => {
    try {
      await walletService.wallet.disconnect()
      dispatch(userActions.setUserData({address:null}));
      window.location.reload();
    } catch (e) {
      console.error(e);
    }
  }


  const chooseWalletToConnect = () => {
    toggleModal({
      isOpen:true,
      text: (
      <div>
        <div className="m10">
          Choose wallet to connect
        </div>

        { (networkFrom==='Ethereum') &&
        <>
          <div
              className="button m10"
              onClick={ async () => {
                dispatch(walletActions.setWalletType('metamask'))
                toggleModal({ isOpen: false })
              } }
          >
            <IconArrowWhite className="button-arrow"/>
            Metamask
          </div>
          <div
              className="button m10"
              onClick={ () => {
                dispatch(walletActions.setWalletType('walletConnect'))
                toggleModal({ isOpen: false })
              } }
          >
            <IconArrowWhite className="button-arrow"/>
            WalletConnect
          </div>
        </>
        }

        { ['Binance-Chain','Binance-Smart-Chain'].includes(networkFrom) &&
        <>
          <div
              className="button m10"
              onClick={ () => {
                dispatch(walletActions.setWalletType('binance'))
                toggleModal({ isOpen: false })
              } }
          >
            <IconArrowWhite className="button-arrow"/>
            Binance Chain Wallet
          </div>
          <div
              className="button m10"
              onClick={ () => {
                dispatch(walletActions.setWalletType('walletConnect'))
                toggleModal({ isOpen: false })
              } }
          >
            <IconArrowWhite className="button-arrow"/>
            WalletConnect
          </div>
        </>
        }
      </div>
      ),
    });
  }

  React.useState(() => {
    setDefaultProperties();
  }, []);

  React.useEffect(()=>{
    if (!networkFrom) return;
    getNetworksTo()
    getAddresses()
  },[networkFrom])

  React.useEffect(()=>{
    if (!networkTo) return;
    amount && handleChangeAmount(amount)
    if (!dexList) return;
    getFee()
    getAddresses()
    getMinimumAmount()
    getMaximumAmountXDCE()
  },[networkTo,dexList])

  React.useEffect(async ()=>{
    if (!userAddress) return;
    if (!contractService) return;
    // if (amount==='0') return;

    !isNetworkFromBinanceChain && checkAllowance()
  },[amount,userAddress,contractService])

  return (
    <form className={currentToken === 'Wish' ? 'form form-wish' : 'form'} type="submit">

      <div className="form-label-uppercase">
        Select a token<ClipLoader color={'#909090'} loading={isPending} css={"margin-right: 2px"} size={10} />
      </div>

      <Dropdown
          label="Select a token"
          disabled={ isPending }
          items={ currentTokens }
          value={ currentToken }
          onChange={ handleChangeCurrentToken }
      />

      <div className="form-label-uppercase">
        From
      </div>

      <Dropdown
      label="Choose network"
      items={ networksFrom }
      value={ networkFrom }
      onChange={ handleChangeNetworkFrom }
      />

      <div className="form-label-uppercase">
        To
      </div>

      <Dropdown
      label="Choose destination network"
      items={ networksTo }
      value={ networkTo }
      onChange={ handleChangeNetworkTo }
      />

      <div className="form-label-uppercase">
        <div>
          Amount
        </div>
        { currentToken !== 'Xinfin' && currentToken !== 'XDCE' ?
            <a
                className="form-label-link-right"
                href={ getTokenLink(networkFrom,tokenAddressFrom) }
                target="_blank"
            >
              <div className="link">{ getTokenSymbol(networkFrom, networkTo) }</div>
              <IconLink className="input-label-inner-image"/>
            </a>
        : null}

        { currentToken === 'XDCE' ?
            <a
                className="form-label-link-right"
                href={ 'https://etherscan.io/token/0x41ab1b6fcbb2fa9dced81acbdec13ea6315f2bf2' }
                target="_blank"
            >
              <div className="link">XDCE</div>
              <IconLink className="input-label-inner-image"/>
            </a>
            : null}

      </div>

      <Input
      name="amount"
      medium formatNumber
      disabled={ isPending }
      error={ form.amount }
      type="text"
      placeholder="0.00"
      value={ amount }
      onChange={ currentToken === 'XDCE' ? amountDebounce : handleChangeAmount }
      onFocus={ handleFocusAmount }
      label={ userAddress && !isNetworkFromBinanceChain &&
        <>
          <div></div>
          <div
          className="pointer"
          onClick={ handleSendMax }
          >
            Send max
          </div>
        </>
      }
      />
      { currentToken === 'XDCE' ? null :

      <>
        <div className="form-label-under">
          <div className="form-label-left">
            Fee: { isPending ? <ClipLoader color={'#909090'} loading={isPending} css={"margin-bottom: -2px"} size={8} /> : fee }
          </div>
        </div>
      </>
      }


      { form.amount && form.amount.text.includes('Amount') &&
      <div className="form-label-error">
        <IconError className="form-label-error-icon"/>
        { form.amount.text }
      </div>
      }

      { form.amount && form.amount.text.includes('The minimum') &&
      <div className="form-label-error">
        <IconError className="form-label-error-icon"/>
        { form.amount.text }
      </div>
      }

      <div className="form-label-uppercase">
        Destination address
      </div>

      <Input
      error={ form.receiver }
      placeholder='Enter destination address'
      value={ receiver }
      onChange={ handleChangeReceiver }
      />

      { form.receiver &&
      <div className="form-label-error">
        <IconError className="form-label-error-icon"/>
        { form.receiver.text }
      </div>
      }

      <div className="form-label-uppercase">
        <div>
          You will receive
        </div>
        { currentToken !== 'Xinfin' ?
            <a
                className="form-label-link-right"
                href={ currentToken === 'XDCE' ? null : getTokenLink(networkTo,tokenAddressTo) }
                target="_blank"
            >
              <div className="link">{ currentToken === 'XDCE' ? null : getTokenSymbol(networkTo, networkFrom) }</div>
              { currentToken === 'XDCE' ? null : <IconLink className="input-label-inner-image"/>}
            </a>
        : null}
      </div>

      <Input
      name="receive"
      medium disabled formatNumber
      type="text"
      placeholder="0.00"
      value={ receive }
      />

      <div className="form-label-note">
        <IconInfo className="form-label-error-icon"/>
        Note: Transfer from { networkFrom && networkFrom.split('-').join(' ') }{' '}
        Network {' '}
        { isNetworkFromBinanceChain && 'manually or' } with {' '}
        { isNetworkFromEthereum && 'Metamask'}{' '}
        { isNetworkFromBinanceChain && 'Binance Chain'}{' '}
        { isNetworkFromXinfin && 'XinPay'}{' '}
        wallet
      </div>
      <div className="form-label-note">{ isNetworkFromXinfin && 'Make sure that you have Metamask turned off'}</div>
      <div className={ isNetworkFromBinanceChain ? "form-buttons" : "form-buttons-one" }>
        { isNetworkFromBinanceChain &&
        <div
        className="button mt20"
        onClick={ handleSwapManual }
        >
          <IconArrowWhite className="button-arrow"/>
          Manually
        </div>
        }

        {/*{ userAddress &&*/}
        {/*<div*/}
        {/*    className="button mt20"*/}
        {/*    onClick={ handleDisconnectWalletConnect }*/}
        {/*>*/}
        {/*  <IconArrowWhite className="button-arrow"/>*/}
        {/*  Disconnect*/}
        {/*</div>*/}
        {/*}*/}

        { !isNetworkFromXinfin ?
        (<>
          { (!userAddress) ?
              <div
                  className={`button mt20 ${ isPending ? 'button-disabled' : ''}`}
                  onClick={ chooseWalletToConnect }
              >
                <IconArrowWhite className="button-arrow"/>
                Connect wallet
              </div>
              : (approved || isNetworkFromBinanceChain || isNetworkFromXinfin) ?
                  <div
                      className={ `button mt20 ${ !userAddress ? 'button-disabled' : '' }`}
                      onClick={ handleSwap }
                  >
                    <IconArrowWhite className="button-arrow"/>
                    Swap
                  </div>
                  :
                  <div
                      className={ `button ${ approving ? 'button-disabled' : '' } mt20` }
                      onClick={ !approving ? approve : () => {} }
                  >
                    <IconArrowWhite className="button-arrow"/>
                    { approving ? 'Waiting...' : 'Approve'}
                  </div>
          }
        </>) : (
            <>
              { !xinpayWallet?.connected ? (<XdcConnect
                  btnClass={
                    xinpayWallet?.connected
                      ? "btn btn-rounded btn-success"
                      : `button-test mt20 ${isPending ? 'button-disabled' : ''}`
              }
                btnName={
                xinpayWallet?.connected
                ? "XinPay successfully connected"
                : <><IconArrowWhite className="button-arrow"/>Connect XinPay</>
              }
                onConnect={(wallet) => {
                console.log("user connected wallet", wallet);
                setXinpayWallet(wallet);
                dispatch(userActions.setUserData({address: wallet.address}))
              }}
                onDisconnect={(wallet) => {
                console.log("user connected disconnect", wallet);
                setXinpayWallet(wallet);
                dispatch(walletActions.setWalletType('xinpay'))
              }}
                />) : <div
                  className="button mt20"
                  onClick={ handleSwap }balance
              >
                <IconArrowWhite className="button-arrow"/>
                Swap
              </div>}
            </>
            )}

      </div>
    </form>
  );
}

export default Form;
