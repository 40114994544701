import Web3 from 'web3';
import BigNumber from "bignumber.js";
import {isEqual} from 'lodash/lang';
import config from '../../config';


const chainIds = {
    mainnet: {
        'Ethereum': {
            name: 'Mainnet',
            id: 1,
        },
        'Binance-Chain': {
            name: 'Binance chain',
            id: 'Binance-Chain-Tigris', //TODO: проверить
        },
        'Binance-Smart-Chain': {
            name: 'Binance smart chain',
            id: 56,
        },
    },
    testnet: {
        'Ethereum': {
            name: 'Ropsten testnet',
            id: 3,
        },
        'Binance-Chain': {
            name: 'Binance chain testnet',
            id: 'Binance-Chain-Ganges', //TODO: проверить
        },
        'Binance-Smart-Chain': {
            name: 'Binance smart chain testnet',
            id: 97,
        },
    },
}

export default class WalletConnectService {
    constructor({provider,networkFrom,contractDetails}) {
        console.log('WalletConnectService',contractDetails)
        this.name = 'metamask'
        this.wallet = provider;
        this.net = config.IS_PRODUCTION ? 'mainnet' : 'testnet'
        this.networkFrom = networkFrom
        this.providers = {};
        this.Web3Provider = new Web3(this.wallet);
        this.wallet.on('chainChanged', (newChain) => {
            console.log('chainChanged',newChain)
            window.location.reload()
        });
        this.wallet.on('accountsChanged', (newAccounts) => {
            console.log('accountsChanged',newAccounts)
            const accounts = JSON.parse(localStorage.getItem('accounts'))
            if (!accounts || !isEqual(accounts.accounts,newAccounts)) {
                localStorage.setItem('accounts',JSON.stringify({accounts:newAccounts}))
                window.location.reload()
            }
        });
        this.wallet.on("disconnect", (code, reason) => {
            console.log('WalletConnect disconnected',code, reason);
            console.log('WalletConnect disconnected', {code, reason});
        });
        this.contractAddressToken = contractDetails.ADDRESS.TOKEN[networkFrom];
        this.contractAddressSwap = contractDetails.ADDRESS.SWAP[networkFrom];
        this.contractAbiToken = contractDetails.ABI.TOKEN[networkFrom];
        this.contractAbiSwap = contractDetails.ABI.SWAP[networkFrom];
        this.contractDecimals = contractDetails.DECIMALS.TOKEN[networkFrom];
    }

    async createSession() {
        try {
            const chainId = this.wallet.chainId;
            await this.wallet.connector.createSession({chainId});
            return true
        } catch (e) {
            console.error(e);
            return false
        }
    }

    async approveSession() {
        try {
            const chainId = this.wallet.chainId;
            await this.wallet.connector.approveSession({
                chainId,
                accounts: this.wallet.accounts,
            });
            return true
        } catch (e) {
            console.error(e);
            return false
        }
    }

    async updateSession() {
        try {
            const chainId = this.wallet.chainId;
            await this.wallet.connector.updateSession({
                chainId,
                accounts: this.wallet.accounts,
            });
            return true
        } catch (e) {
            console.error(e);
            return false
        }
    }

    async rejectSession() {
        try {
            await this.wallet.connector.rejectSession({});
            return true
        } catch (e) {
            console.error(e);
            return false
        }
    }

    async killSession() {
        try {
            const oldWallet = this.wallet;
            oldWallet.removeListener('disconnect', () => {});
            oldWallet.on('disconnect', async () => {
                // await this.applyAccount();
                // this.createProvider();
                // this.connectWalletProvider(chainId, resolve);
            });
            await this.wallet.connector.killSession();
            return true
        } catch (e) {
            console.error(e);
            return false
        }
    }


    getAccount() {
        if (!this.wallet) {
            return {
                errorMsg: `${this.name} wallet is not injected`
            }
        }
        return new Promise((resolve, reject) => {
            const usedNet = chainIds[this.net][this.networkFrom].id
            const netVersion = this.wallet.chainId
            const neededNetName = chainIds[this.net][this.networkFrom].name
            console.log('getAccount netVersion', netVersion)
            if (!netVersion || netVersion===null) {
                this.wallet.request({ method: 'eth_chainId' }).then(netVersion => {
                    if (netVersion === usedNet) {
                        resolve({address:this.wallet.accounts[0]})
                    } else {
                        reject({
                            errorMsg: `Please choose ${neededNetName} network in your remote wallet`
                        })
                    }
                })
                    .catch(() => reject({ errorMsg: 'Not authorized' }))
            } else {
                if (netVersion === usedNet) {
                    console.log('getAccount nets are the same')
                    resolve({address:this.wallet.accounts[0]})
                } else {
                    reject({
                        errorMsg: `Please choose ${neededNetName} network in your remote wallet`
                    })
                }
            }
        })
    }

    getContract(abi, address) {
        return new this.Web3Provider.eth.Contract(abi, address);
    }

    validateAddress(address) {
        return this.Web3Provider.utils.isAddress(address);
    }

    transferToOtherBlockchain = ({ userAddress, blockchain, amount, receiver, callback }) => {
        const approveMethod = this.getMethodInterface('transferToOtherBlockchain', this.contractAbiSwap);
        const approveSignature = this.encodeFunctionCall(approveMethod, [
            `0x${blockchain}`,
            new BigNumber(+amount).times(Math.pow(10, this.contractDecimals)).toString(10),
            receiver,
        ]);
        const approveTransaction = () => {
            return this.sendTransaction({
                from: userAddress,
                to: this.contractAddressSwap,
                data: approveSignature,
            }, callback);
        };
        const transaction = {
            title: 'transferToOtherBlockchain',
            to: this.contractAddressSwap,
            data: approveSignature,
            action: approveTransaction,
            onComplete: callback
        };
        this.createTransactionObj(transaction, userAddress)
    }

    approveToken = (walletAddress, tokenAddress, amount, callback,) => {
        const approveMethod = this.getMethodInterface('approve', this.contractAbiToken);
        const approveSignature = this.encodeFunctionCall(approveMethod, [
            tokenAddress,
            new BigNumber(amount).times(Math.pow(10, this.contractDecimals)).toString(10),
        ]);
        const approveTransaction = () => {
            return this.sendTransaction({
                from: walletAddress,
                to: this.contractAddressToken,
                data: approveSignature,
            }, callback);
        };
        const transaction = {
            title: 'Authorise the contract, approving tokens',
            to: this.contractAddressToken,
            data: approveSignature,
            action: approveTransaction,
            onComplete: callback
        };
        this.createTransactionObj(transaction, walletAddress)
    }


    sendTx = async (methodName, addressFrom, data, amount) => {
        try {
            const method = this.getMethodInterface(methodName, this.contractAbiToken); //TODO:contractAbiToken
            const signature = this.encodeFunctionCall(method, data);
            const params = {
                from: addressFrom,
                to: this.contractAddressToken,
                value: amount,
                data: signature,
            };
            const txHash = await this.wallet.request({
                method: 'eth_sendTransaction',
                params: [params],
            });
            const txReceipt = new Promise((resolve, reject) => {
                const trxSubscription = setInterval(() => {
                    this.Web3Provider.eth.getTransactionReceipt(
                        txHash,
                        (error, transaction) => {
                            if (transaction) {
                                if (transaction.status) {
                                    resolve(transaction);
                                } else {
                                    reject(error);
                                }
                                clearInterval(trxSubscription);
                            }
                            if (error) {
                                clearInterval(trxSubscription);
                            }
                        },
                    );
                }, 1000);
            })
            return await txReceipt;
        } catch (e) {
            console.error(e);
        }
    }

    encodeFunctionCall(abi, data) {
        return this.Web3Provider.eth.abi.encodeFunctionCall(abi, data);
    }

    getMethodInterface(methodName, abi) {
        return abi.filter((m) => {
            return m.name === methodName;
        })[0];
    }

    prepareTransaction(wallet, transaction) {
        transaction.action(wallet)
    }

    createTransactionObj(transaction, walletAddress) {
        this.prepareTransaction({
            type: 'metamask',
            address: walletAddress,
        }, transaction);
    }

    sendTransaction(transactionConfig, callback, errCallback) {
        this.wallet.request({
            method: 'eth_sendTransaction',
            params: [transactionConfig]
        }).then(() => {
            if (callback) {
                setTimeout(() => callback(true), 5000)
            }
        }).catch((error) => {
            console.log(error, 'error')
            callback(false)
        });
    }


}
