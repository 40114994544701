import React, { createContext, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { XdcConnect, Disconnect, SendTransaction, GetWallet } from "xdc-connect";
import WalletConnectProvider from "@walletconnect/web3-provider";

import {
  BinanceService,
  ContractService,
  MetamaskService,
  BackendService,
  getFromStorage,
  XinpayService,
  WalletConnectService
} from '../../utils';
import {userActions, modalActions, walletActions, pendingActions, tokenActions} from '../../redux/actions';
import config from '../../config';

const contractContext = createContext({
  walletService: null,
  contractService: null,
})

const backendService = new BackendService()

const ContractProvider = ({ children }) => {
  const [walletService, setWalletService] = React.useState(null)
  const [contractService, setContractService] = React.useState(null)
  const [contractDetails, setContractDetails] = React.useState(null)
  const [token, setToken] = React.useState('Wish');

  const dispatch = useDispatch();
  const toggleModal = (props) => dispatch(modalActions.toggleModal(props))

  const { walletType, networkFrom, networkTo } = useSelector(({ wallet }) => ({
    walletType: wallet.type,
    networkFrom: wallet.networkFrom,
    networkTo: wallet.networkTo,
  }))

  const currentToken = useSelector(({ token }) => token.name);
  const isPending = useSelector(({ pending }) => pending.isPending);

  const loginXinpay = async () => {
    try {
      console.log('loginXinpay',networkFrom);
      const wallet = new XinpayService({networkFrom,contractDetails});
      await window.ethereum.enable()
      setContractService(new ContractService({ wallet, networkFrom, contractDetails }))
      await wallet.initXdc3();
      await window.ethereum.enable();
      const xinpayWallet = GetWallet();
      console.log(xinpayWallet);

      const account = xinpayWallet.wallet.address;
      dispatch(userActions.setUserData(account))
    } catch (e) {
      console.error(e);
    }
  }
  const loginMetamask = async () => {
    try {
      console.log('loginMetamask',networkFrom, currentToken)
      const wallet = new MetamaskService({networkFrom, currentToken, contractDetails})
      // dispatch(modalActions.toggleModal({isOpen:true,text:'MetamaskService'}))
      await window.ethereum.enable()
      // dispatch(modalActions.toggleModal({isOpen:true,text:'ethereum.enable'}))
      setContractService(new ContractService({ wallet, networkFrom, contractDetails }))
      setWalletService(wallet)
      // dispatch(modalActions.toggleModal({isOpen:true,text:'ContractService'}))
      const account = await wallet.getAccount()

      dispatch(userActions.setUserData(account))
    } catch (e) {
      console.error(e);
      if (!e.errorMsg || e.errorMsg==='') {
        toggleModal({
          isOpen:true,
          text:
          <div>
            <p>
              Metamask extension is not found.
            </p>
            <p>
              You can install it from {' '}
              <a href="https://metamask.io" target="_blank">metamask.io</a>
            </p>
          </div>
        })
      } else {
        toggleModal({isOpen:true,text:e.errorMsg})
      }
    }
  }

  const loginWalletConnect = async () => {
    let provider = new WalletConnectProvider({
      rpc: config.rpc(),
    });
    try {
      // console.log('loginWalletConnect',provider)
      let account = provider.accounts[0];
      console.log('loginWalletConnect accounts',account)
      if (account) {
        console.log('loginWalletConnect disconnect')
        await provider.disconnect();
      }
      await provider.enable();
      provider.on('chainChanged', (newChain) => {
        console.log('chainChanged',newChain)
        loginWalletConnect();
      });
      provider.on('disconnect', (newChain) => {
        console.log('chainChanged',newChain)
        // loginWalletConnect();
      });
      // const wallet = new Web3(provider);
      const wallet = new WalletConnectService({
        provider,networkFrom,contractDetails
      })
      setContractService(new ContractService({
        wallet, networkFrom, contractDetails, walletType
      }));
      setWalletService(wallet);
      const resultGetAccount = await wallet.getAccount();
      console.log('loginWalletConnect',resultGetAccount)
      dispatch(userActions.setUserData(resultGetAccount));
    } catch (e) {
      console.error(e);
      if (e.message==='User closed modal') return window.location.reload();
      if (!e.errorMsg || e.errorMsg==='') {
        return toggleModal({isOpen:true,text:e.errorMsg})
      } else {
        return toggleModal({isOpen:true,text:e.errorMsg})
      }
      await provider.disconnect();
    }
  }

  const loginBinance = async () => {
    try {
      console.log('loginBinance',networkFrom)
      const wallet = new BinanceService({networkFrom,contractDetails})
      setContractService(new ContractService({ wallet, networkFrom, contractDetails }))
      setWalletService(wallet)
      const account = await wallet.getAccount()
      dispatch(userActions.setUserData(account))
    } catch (e) {
      console.error(e);
      if (!e.errorMsg || e.errorMsg==='') {
        toggleModal({
          isOpen:true,
          text:
          <div>
            <p>
              Binance Chain Wallet is not found.
            </p>
            <p>
              You can install it from {' '}
              <a href="https://www.binance.org" target="_blank">binance.org</a>
            </p>
          </div>
        })
      } else {
        toggleModal({isOpen:true,text:e.errorMsg})
      }
    }
  }


  const getDex = async (name) => {
    try {
      const resultGetDex = await backendService.getDex({name});
    } catch (e) {
      console.log(e);
    }
  }

  const getDexList = async () => {
    try {
      dispatch(pendingActions.setPendingState({isPending: true}))
      const resultGetDexList = await backendService.getDexList(currentToken);
      // console.log('resultGetDexList',resultGetDexList.data)
      const dexList = resultGetDexList.data;
      dispatch(walletActions.setWalletDexList(dexList));
      if (!dexList) return toggleModal({
        isOpen:true,text:'Server is offline',
      })
      const binanceChain = dexList.filter((item) => item.network==='Binance-Chain')[0]
      const binanceSmartChain = dexList.filter((item) => item.network==='Binance-Smart-Chain')[0]
      const ethereumChain = dexList.filter((item) => item.network==='Ethereum')[0]
      const xinfinChain = dexList.filter((item) => item.network==='Xinfin')[0]

      console.log('xinfinChain', xinfinChain);

      let contractDetails = {
        ADDRESS: {
          TOKEN: {
            'Ethereum':ethereumChain?.token_address,
            'Binance-Smart-Chain':binanceSmartChain?.token_address,
            'Binance-Chain':binanceChain?.token_address,
            'Xinfin': null,
          },
          SWAP: {
            'Ethereum':ethereumChain?.swap_address,
            'Binance-Smart-Chain':binanceSmartChain?.swap_address,
            'Binance-Chain':binanceChain?.swap_address,
            'Xinfin': xinfinChain?.swap_address,
          },
          FEE: {
            'Ethereum':ethereumChain?.fee_address,
            'Binance-Smart-Chain':binanceSmartChain?.fee_address,
            'Binance-Chain':binanceChain?.fee_address,
            'Xinfin':xinfinChain?.fee_address,
          },
        },
        DECIMALS: {
          TOKEN: {
            'Ethereum': ethereumChain?.decimals,
            'Binance-Smart-Chain': binanceSmartChain?.decimals,
            'Binance-Chain': binanceChain?.decimals,
            'Xinfin': xinfinChain?.decimals,
          },
          SWAP: {
            'Ethereum':ethereumChain?.decimals,
            'Binance-Smart-Chain':binanceSmartChain?.decimals,
            'Binance-Chain':binanceChain?.decimals,
            'Xinfin':xinfinChain?.decimals,
          },
        },
        ABI: {
          TOKEN: {
            'Ethereum':ethereumChain?.token_abi,
            'Binance-Smart-Chain':binanceSmartChain?.token_abi,
            'Binance-Chain':binanceChain?.token_abi,
            'Xinfin':xinfinChain?.token_abi,
          },
          SWAP: {
            'Ethereum': ethereumChain?.swap_abi,
            'Binance-Smart-Chain': binanceSmartChain?.swap_abi,
            'Binance-Chain': binanceChain?.swap_abi,
            'Xinfin': xinfinChain?.swap_abi,
          },
        },
        XDCE_ABI: {
          'Ethereum': ethereumChain?.xdce_abi,
        },
        XDCE_ADDRESS: {
          'Ethereum': ethereumChain?.xdce_address,
        },
        XDCE_SWAP_ADDRESS: {
          'Ethereum': ethereumChain?.xdce_swap_address,
        },
        XDCE_SWAP_ABI: {
          'Ethereum': ethereumChain?.xdce_swap_abi,
        }
      }
      console.log('ContractDetails from contractContext', contractDetails);
      setContractDetails(contractDetails)
      dispatch(pendingActions.setPendingState({isPending: false}))
    } catch (e) {
      console.error(e);
      dispatch(pendingActions.setPendingState({isPending: false}))
    }
  }

  React.useEffect(() => {
    if (walletType==='metamask') {
      loginMetamask()
    }
    if (walletType==='binance') {
      loginBinance()
    }
    if (walletType==='xinpay') {
      loginXinpay()
    }
    if (walletType==='walletConnect') {
      loginWalletConnect()
    }
  }, [walletType])

  React.useEffect(() => {
    getDexList();
  }, [])

  React.useEffect(() => {
    if (token !== currentToken) {
      getDexList();
      setToken(currentToken);
    }
  })

  return (
    <contractContext.Provider value={{ walletService, contractService }}>
      {children}
    </contractContext.Provider>
  );
}

export default ContractProvider;

export function useContractContext() {
  return useContext(contractContext)
}
