import Web3 from 'web3';
import Xdc3 from 'xdc3';
import BigNumber from "bignumber.js";
import {isEqual} from 'lodash/lang';
import config from '../../config';
import _ from "lodash";
import { XdcConnect, Disconnect, SendTransaction, GetWallet } from "xdc-connect";


export default class XinpayService {
    constructor({networkFrom,contractDetails}) {
        console.log('XinpayService',contractDetails)
        console.log('networkFrom', networkFrom);
        this.networkFrom = networkFrom;
        this.name = 'xinfin'
        this.wallet = window.ethereum;
        this.net = config.IS_PRODUCTION ? 'mainnet' : 'testnet'
        this.nets = {
            mainnet: {
                'Ethereum': {
                    name: 'Mainnet',
                    code: '0x1',
                },
                'Xinfin': {
                    name: 'Xinfin',
                    code: '0x33'
                }
            },
            testnet: {
                'Ethereum': {
                    name: 'Ropsten testnet',
                    code: '0x3',
                },
            },
        }
        this.providers = {};
        this.Xdc3Provider = new Xdc3(this.wallet);
        this.Web3Provider = new Web3(this.wallet);
        this.wallet.on('chainChanged', (newChain) => {
            window.location.reload()
        });
        this.wallet.on('accountsChanged', (newAccounts) => {
            console.log('accountsChanged',newAccounts)
            const accounts = JSON.parse(localStorage.getItem('accounts'))
            if (!accounts || !isEqual(accounts.accounts,newAccounts)) {
                localStorage.setItem('accounts',JSON.stringify({accounts:newAccounts}))
                window.location.reload()
            }
        });

        this.contractAddressToken = contractDetails.ADDRESS.TOKEN[networkFrom];
        this.contractAddressSwap = contractDetails.ADDRESS.SWAP[networkFrom];
        this.contractAbiToken = contractDetails.ABI.TOKEN[networkFrom];
        this.contractAbiSwap = contractDetails.ABI.SWAP[networkFrom];
        this.contractDecimals = contractDetails.DECIMALS.TOKEN[networkFrom];
    }

    isXdc3Supported() {
        return Boolean(window.ethereum);
    }

    async isLocked() {
        let xdc3 = new Xdc3(window.ethereum);
        const accounts = await xdc3.eth.getAccounts();
        return _.isEmpty(accounts);
    }

    async initXdc3() {
        try {
            const isLocked = await this.isLocked();
            if (isLocked === true) {
               console.log("Please unlock XinPay wallet to continue");
                return null;
            }
            const isXdc3Supported = this.isXdc3Supported();
            if (!isXdc3Supported) {
                console.log('xdc is not available');
                return null;
            }
            // const isConnected = await window.ethereum.isConnected();
            await window.ethereum.enable();
            await this._initListerner();
            const xdc3 = new Xdc3(window.ethereum);
            const accounts = await xdc3.eth.getAccounts();
            const addresses = accounts;
            const chain_id = await xdc3.eth.getChainId();
            return chain_id;
        } catch (e) {
            console.log(e);
        }
    }

    async _initListerner() {
        window.ethereum.removeAllListeners();
        window.ethereum.on("connect", async (data) => {
            const accounts = await this.Xdc3Provider.eth.getAccounts();
            const chain_id = await this.Xdc3Provider.eth.getChainId();
        });
    }

     getAccount() {
        if (!this.wallet) {
            return {
                errorMsg: `${this.name} wallet is not injected`
            }
        }
        return new Promise((resolve, reject) => {
            let net,usedNet;
            if ((config.IS_PRODUCTION || config.IS_XINFIN) && this.networkFrom !== 'Xinfin') {
                net = 'mainnet'
                usedNet = '0x1'
            }
            if (!config.IS_PRODUCTION && this.networkFrom !== 'Xinfin') {
                net = 'ropsten'
                usedNet = '0x3'
            }
            if (this.networkFrom === 'Xinfin') {
                net = 'Xinfin'
                usedNet = '0x33'
            }
            const xinpayWallet = GetWallet();
            console.log(xinpayWallet);

            const netVersion = xinpayWallet.wallet.chain_id;
            console.log('getAccount netVersion', netVersion)
            return xinpayWallet.wallet.address;
            if (!netVersion || netVersion===null) {
                this.wallet.request({ method: 'eth_chainId' })
                    .then(netVersion => {
                        if (netVersion === usedNet) {
                            this.wallet.request({ method: 'eth_requestAccounts' })
                                .then(account => resolve({
                                    address: account[0],
                                    network: netVersion,
                                }))
                                .catch(_ => reject({ errorMsg: 'Not authorized' }))
                        } else {
                            reject({
                                errorMsg: 'Please choose ' + net + ' network in metamask wallet'
                            })
                        }
                    })
                    .catch(_ => reject({ errorMsg: 'Not authorized' }))
            } else {
                if (netVersion === usedNet) {
                    this.wallet.request({ method: 'eth_requestAccounts' })
                        .then(account => resolve({
                            address: account[0],
                            network: netVersion,
                        }))
                        .catch(_ => reject({ errorMsg: 'Not authorized' }))
                } else {
                    reject({
                        errorMsg: 'Please choose ' + net + ' network in metamask wallet.'
                    })
                }
            }
        })
    }
    getContract(abi, address) {
        console.log('getContract address', address)
        if (address.slice(0,3) === 'xdc') {
            address = '0x' + address.slice(3);
        }
        return new this.Xdc3Provider.eth.Contract(abi, address);
    }

    validateAddress(address) {
        console.log('validateAddress', address)
        if (address.slice(0,3) === 'xdc') {
            address = '0x' + address.slice(3);
        }
        return this.Xdc3Provider.utils.isAddress(address);
    }

    transferToOtherBlockchain = ({ userAddress, blockchain, amount, receiver, callback, currentToken }) => {
        const approveMethod = this.getMethodInterface('transferToOtherBlockchain', this.contractAbiSwap);
        let approveSignature;
        approveSignature = this.encodeFunctionCall(approveMethod, [
                `0x${2}`,
                receiver,
        ]);
        let value;
        if (currentToken === 'Xinfin') {
            value = new BigNumber(+amount * 10**18);
        }
        const approveTransaction = () => {
            return SendTransaction({
                from: userAddress,
                to: this.contractAddressSwap,
                data: approveSignature,
                value,
            }, callback);
        };
        const transaction = {
            title: 'transferToOtherBlockchain',
            to: this.contractAddressSwap,
            data: approveSignature,
            action: approveTransaction,
            onComplete: callback
        };
        this.createTransactionObj(transaction, userAddress)
    }

    sendTx = async (methodName, addressFrom, data, amount) => {
        try {
            const method = this.getMethodInterface(methodName, this.contractAbiToken); //TODO:contractAbiToken
            const signature = this.encodeFunctionCall(method, data);
            const params = {
                from: addressFrom,
                to: this.contractAddressToken,
                value: amount,
                data: signature,
            };
            const txHash = await this.wallet.request({
                method: 'eth_sendTransaction',
                params: [params],
            });
            const txReceipt = new Promise((resolve, reject) => {
                const trxSubscription = setInterval(() => {
                    this.Xdc3Provider.eth.getTransactionReceipt(
                        txHash,
                        (error, transaction) => {
                            if (transaction) {
                                if (transaction.status) {
                                    resolve(transaction);
                                } else {
                                    reject(error);
                                }
                                clearInterval(trxSubscription);
                            }
                            if (error) {
                                clearInterval(trxSubscription);
                            }
                        },
                    );
                }, 1000);
            })
            return await txReceipt;
        } catch (e) {
            console.error(e);
        }
    }

    encodeFunctionCall(abi, data) {
        console.log('abi', abi);
        console.log('data', data);
        return this.Web3Provider.eth.abi.encodeFunctionCall(abi, data);
    }

    getMethodInterface(methodName, abi) {
        return abi.filter((m) => {
            return m.name === methodName;
        })[0];
    }

    prepareTransaction(wallet, transaction) {
        transaction.action(wallet)
    }

    createTransactionObj(transaction, walletAddress) {
        this.prepareTransaction({
            type: 'metamask',
            address: walletAddress,
        }, transaction);
    }

    sendTransaction(transactionConfig, callback, errCallback) {
        console.log('transactionConfig', transactionConfig);
        this.wallet.request({
            method: 'eth_sendTransaction',
            params: [transactionConfig]
        }).then(() => {
            if (callback) {
                setTimeout(() => callback(true), 5000)
            }
        }).catch((error) => {
            console.log(error, 'error')
            callback(false)
        });
    }
}
