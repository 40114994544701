import React from 'react';
import useMedia from 'use-media';

import config from '../../config';
import './style.scss';
import { ReactComponent as IconLogo } from '../../assets/images/logo-mywish.svg';
import { ReactComponent as IconFacebook } from "../../assets/icons/social/facebook-circle.svg";
import { ReactComponent as IconTelegram } from "../../assets/icons/social/telegram-circle.svg";
import { ReactComponent as IconEmail } from "../../assets/icons/social/email-circle.svg";
import { ReactComponent as IconBitcoin } from "../../assets/icons/social/bitcoin-circle.svg";
import { ReactComponent as IconMedium } from "../../assets/icons/social/medium-circle.svg";
import { ReactComponent as IconTwitter } from "../../assets/icons/social/twitter-circle.svg";
import { ReactComponent as IconGithub } from "../../assets/icons/social/github-circle.svg";
import HeaderDropdown from "../HeaderDropdown";

function Header(props) {
  const {
    withMessage = true,
  } = props;

  const isMobile = useMedia({maxWidth: 1000});

  const [openMenu, setOpenMenu] = React.useState(false);

  return (
    <header className="header">
      <div className="header-container">

        <div className="header-left-group">
          <a href="https://mywish.io/">
            <IconLogo className="header-logo"/>
          </a>
        </div>

        { isMobile &&
        <div
        className="header-menu-btn-wrapper"
        onClick={() => setOpenMenu(!openMenu)}
        >
          {/*<div className={ openMenu ? "header-menu-btn-open" : "header-menu-btn" }></div>*/}
          { !openMenu ? 'Menu' : "Close" }
        </div>
        }

        <div
        className={
          isMobile ?
          (openMenu ? "header-menu-mobile-open" : "header-menu-mobile-closed") :
          "header-right-group"
        }
        >
          <div className="header-item">
            <a href="https://bridge.mywish.io/">
              Crosschain swap
            </a>
          </div>

          <HeaderDropdown
          label="Binance"
          items={ [
            { key: '', text: 'Token', link: 'https://mywish.io/binance-token', image: '' },
          ] }
          />

          <HeaderDropdown
          label="TRON"
          items={ [
            { key: '', text: 'Main', link: 'https://mywish.io/tron', image: '' },
            { key: '', text: 'Token', link: 'https://mywish.io/tron-token', image: '' },
            { key: '', text: 'Tronish', link: 'https://mywish.io/tronish', image: '' },
          ] }
          />

          <HeaderDropdown
          label="EOS"
          items={ [
            { key: '', text: 'Main', link: 'https://mywish.io/eos', image: '' },
            { key: '', text: 'EOSISH', link: 'https://mywish.io/eosish', image: '' },
            { key: '', text: 'Account', link: 'https://mywish.io/create-account', image: '' },
            { key: '', text: 'Token', link: 'https://mywish.io/eos-token', image: '' },
          ] }
          />

          <HeaderDropdown
          label="ETH"
          items={ [
            { key: '', text: 'Token', link: 'https://mywish.io/eth-token', image: '' },
            { key: '', text: 'Airdrop', link: 'https://mywish.io/eth-airdrop', image: '' },
            { key: '', text: 'Token Protector', link: 'https://protector.mywish.io/?_ga=2.29101816.534367305.1612191823-1558337309.1610362001', image: '' },
            { key: '', text: 'STO/Crowdsale', link: 'https://mywish.io/eth-crowdsale', image: '' },
          ] }
          />

          <HeaderDropdown
          label="About us"
          items={ [
            { key: '', text: 'Platform', link: 'https://mywish.io/platform', image: '' },
            { key: '', text: 'Product', link: 'https://mywish.io/product', image: '' },
            { key: '', text: 'Team', link: 'https://mywish.io/team', image: '' },
            { key: '', text: 'Blog', link: 'https://mywish.io/news', image: '' },
          ] }
          />

          <HeaderDropdown
          round
          items={ [
            { key: 'en', text: 'en', link: 'https://mywish.io/platform', image: require('../../assets/icons/en.svg').default },
          ] }
          value={ 'en' }
          />

          {/*<div*/}
          {/*className={ `button ${!openMenu && 'ml10'}` }*/}
          {/*onClick={() => {*/}
          {/*  dispatch(modalActions.toggleModal({*/}
          {/*    isOpen:true,*/}
          {/*    text: (*/}
          {/*    <div>*/}
          {/*      <div*/}
          {/*      className="m10"*/}
          {/*      >*/}
          {/*        Choose wallet to connect*/}
          {/*      </div>*/}
          {/*      <div*/}
          {/*      className="button m10"*/}
          {/*      onClick={ () => {*/}
          {/*        dispatch(modalActions.toggleModal({ isOpen: false }))*/}
          {/*        dispatch(walletActions.setWalletType('metamask'))*/}
          {/*      } }*/}
          {/*      >*/}
          {/*        Metamask*/}
          {/*      </div>*/}
          {/*      /!*<div*!/*/}
          {/*      /!*className="button m10"*!/*/}
          {/*      /!*onClick={ () => {*!/*/}
          {/*      /!*  dispatch(modalActions.toggleModal({ isOpen: false }))*!/*/}
          {/*      /!*  dispatch(walletActions.setWalletType('binance'))*!/*/}
          {/*      /!*} }*!/*/}
          {/*      /!*>*!/*/}
          {/*      /!*  Binance*!/*/}
          {/*      /!*</div>*!/*/}
          {/*    </div>*/}
          {/*    ),*/}
          {/*  }))*/}
          {/*}}*/}
          {/*>*/}
          {/*  <IconArrowWhite className="button-arrow"/>*/}
          {/*  Connect wallet*/}
          {/*</div>*/}


          { isMobile &&
          <div className="header-menu-footer">
            <div className="header-menu-footer-logo-group">
              <a href={ config.links.facebook } target="_blank" rel="noreferrer noopener">
                <IconFacebook className="header-menu-footer-logo"/>
              </a>
              <a href={ config.links.telegram } target="_blank" rel="noreferrer noopener">
                <IconTelegram className="header-menu-footer-logo"/>
              </a>
              <a href={ 'mailto:' + config.links.email } target="_blank" rel="noreferrer noopener">
                <IconEmail className="header-menu-footer-logo"/>
              </a>
              <a href={ config.links.bitcointalk } target="_blank" rel="noreferrer noopener">
                <IconBitcoin className="header-menu-footer-logo"/>
              </a>
              <a href={ config.links.medium } target="_blank" rel="noreferrer noopener">
                <IconMedium className="header-menu-footer-logo"/>
              </a>
              <a href={ config.links.twitter } target="_blank" rel="noreferrer noopener">
                <IconTwitter className="header-menu-footer-logo"/>
              </a>
              <a href={ config.links.github } target="_blank" rel="noreferrer noopener">
                <IconGithub className="header-menu-footer-logo"/>
              </a>
            </div>
            <div className="header-menu-footer-copyright">
              © Copyright MyWish 2021, {' '}
              <a className="link" href={ config.links.policy } target="_blank" rel="noreferrer noopener">
                Privacy policy
              </a>
            </div>
          </div>
          }
        </div>

      </div>
      {withMessage &&
      <div className="header-message">
        <a href="https://mywishplatform.typeform.com/to/tlf8MEbl" target="_blank">
          <div>
            Apply for Cross-Chain Bridge Service to have your token wrapped to other blockchains
          </div>
        </a>
      </div>
      }
    </header>
  );
}

export default Header;
