import React, {useState} from 'react';

import Header from "./components/Header";
import Footer from "./components/Footer";
import Form from "./components/Form";
import Modal from "./components/Modal";
import bg from "./assets/images/bg.png";
import {ReactComponent as IconArrowBlue} from "./assets/icons/arrow-down-blue.svg";
import { ReactComponent as IconXdc } from "./assets/icons/crypto/xdc-circle-big.svg";
import { ReactComponent as IconArrow } from "./assets/images/arrow.svg";
import config from "./config";
import { useSelector } from "react-redux";

function App() {
  const [wallet, setWallet] = useState();
  const { dexList } = useSelector(({ wallet }) => wallet);

  const formatTokenLink = (network) => {
    if (!dexList) return '';
    const dex = dexList.filter((item) => item.network===network)[0];

    switch(network) {
      case 'Binance-Chain':
            return dex?.token_address ? 'https://explorer.binance.org/asset/WISH-2D5' + `/token/${dex.token_address}` : 'https://explorer.binance.org/asset/WISH-2D5';
      case 'Ethereum':
            return dex?.token_address ? config.tokenLinks()['ethereum'] + `/token/${dex.token_address}` : config.tokenLinks()['ethereum'];
      case  'Binance-Smart-Chain':
            return;
      case  'Xinfin':
            return dex?.token_address ? config.tokenLinks()['xinfin'] + `/token/${dex.token_address}` : config.tokenLinks()['xinfin'];
      default: return null;

    }

    // return network === 'Binance-Chain' ? 'https://explorer.binance.org/asset/WISH-2D5' :
    // config.tokenLinks()[
    // network === 'Ethereum' ?
    // 'ethereum' : network === 'Binance-Smart-Chain' ?
    // 'binanceSmartChain' : 'binanceChain'
    // ] + `/token/${dex.token_address}`
  }

  return (
    <div className="App" style={{ backgroundImage: `url(${bg})` }}>
      <div className="App-container">
        <Header/>
        <div className="body" style={{ opacity: '1'}}>

          <div className="info">
            <h1>
              MyWish Crosschain Swap
            </h1>
            <div className="xinfin-info">
              <div style={{marginRight: '20px'}}>
                <IconXdc />
              </div>
              <div>
                <div className="xinfin-upper-text" style={{ marginBottom: '22px', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                  <div >Dear users,</div>
                  {/*<div className="xinfin-live">*/}
                  {/*  LIVE <div className="circle-white"></div>*/}
                  {/*</div>*/}
                </div>
                <div> XDCE swap is currently unavailable</div>
              </div>
            </div>

            <div className="xinfin-info-second">
              <div className="xinfin-info-second--element" style={{ display: 'flex'}}>
                <span className="box">XDCE</span>
                <div style={{ margin: '4px 8px 0 8px'}}>
                  <IconArrow/>
                </div>
                <span className="box">XDC swaps info</span>
              </div>
              <div className="xinfin-info-second--element">Now users can only swap tokens with 1:1 ratio</div>
              <div className="xinfin-info-second--element">
                The swaps are running in the following way:<br/>
                The max amount of tokens users can receive in XDC will be<br/>
                calculated according to the snapshot results at the following<br/>
                dates and the assigned ratios
              </div>
              <div className="xinfin-info-second--element" style={{ padddingRight: '72px'}}>
                <div className="box-row">
                  <div className="box-container">
                    <span className="box-2">1.</span>
                    <div className="text" style={{ opacity: .5}}>Before 6th Dec<br/>
                      5.30GMT can be<br/>
                      swapped: 1:1
                    </div>
                  </div>
                  <div className="box-container">
                    <span className="box-2">2.</span>
                    <div className="text" style={{ opacity: .5}}>
                      6th Dec 5.30 GMT —<br/>
                      10th Dec 5.30 GMT can<br/>
                      be swapped 3:1</div>
                  </div>
                </div>
                <div className="box-row">
                  <div className="box-container">
                    <span className="box-2">3.</span>
                    <div className="text" style={{ opacity: .5}}>
                      10th Dec 5.30 GMT —<br/>
                      20th Dec 5.30 GMT can<br/>
                      be swapped 5:1
                    </div>
                  </div>
                  <div className="box-container" style={{ marginBottom: '36px'}}>
                    <span className="box-2">4.</span>
                    <div className="text" style={{ opacity: .5}}>20th Dec 5.30 GMT —<br/>
                      No swap will be<br/>
                      provided after 20th<br/>
                      Dec 2020</div>
                  </div>
                </div>
                <div className="xinfin-info-second--element" style={{ marginBottom: 0, opacity: 1}}><span style={{ opacity: .5}}>Contact support team on</span>{' '}<a href="https://t.me/xdcetoxdcswap" style={{ color: '#2873FB'}}>Telegram</a><span style={{ opacity: .5}}>{' '}for XDCE swap support</span></div>
              </div>
              {/*<div className="xinfin-info-second--element"><span style={{ opacity: '.5'}}>Find more details in the XDC announcement:</span> {' '}<a href="https://mywish.io" style={{ color: '#2873FB'}}>link</a></div>*/}
            </div>

            <div className="description">
              <div>
                WISH token is now available in {' '}
                <a
                className="link-underlined"
                href={ formatTokenLink('Binance-Chain') }
                target="_blank"
                >
                  Binance Chain
                </a>
                , {' '}
                <a
                className="link-underlined"
                href={ formatTokenLink('Binance-Smart-Chain') }
                target="_blank"
                >
                  Binance Smart Chain
                </a> and {' '}
                <a
                className="link-underlined"
                href={ formatTokenLink('Ethereum') }
                target="_blank"
                >
                  Ethereum
                </a>
                {/*  and {' '}*/}
                {/*<a*/}
                {/*    className="link-underlined"*/}
                {/*    href={ formatTokenLink('Xinfin') }*/}
                {/*    target="_blank"*/}
                {/*>*/}
                {/*  Xinfin*/}
                {/*</a>*/}
                <div>
                  You can swap WISH token between the blockchains anytime.
                </div>
              </div>
            </div>
            <div className="links">
              <div className="links-item">
                <div>
                  Got a problem? Just get in touch.
                </div>
                {/*<IconArrowBlue className={ "links-arrow-down" }/>*/}
              </div>
              <div>
                <div>
                  If you have any problems or questions please do not hesitate
                  to contact our support in {' '}
                  <a href={ config.links.telegram } target="_blank" rel="noreferrer noopener">
                    Telegram
                  </a>
                  {' '} or by {' '}
                  <a href={ 'mailto:' + config.links.email } target="_blank" rel="noreferrer noopener">
                    { config.links.email }
                  </a>
                </div>
              </div>
            </div>
          </div>

          <Form/>

        </div>

        <Footer/>
        <Modal/>

      </div>
    </div>
  );
}

export default App;
