import React from "react";
import BigNumber from "bignumber.js";
import config from '../../config';


export default class ContractService {

  constructor({wallet,networkFrom,contractDetails, walletType}) {
    console.log('ContractService:',networkFrom, contractDetails);
    this.wallet = wallet;
    this.net = config.IS_PRODUCTION ? 'mainnet' : 'testnet';
    this.contractAddressToken = contractDetails.ADDRESS.TOKEN[networkFrom];
    this.contractAddressXDCEToken = contractDetails.XDCE_ADDRESS[networkFrom];
    this.contractAddressSwap = contractDetails.ADDRESS.SWAP[networkFrom];
    this.contractAbiToken = contractDetails.ABI.TOKEN[networkFrom];
    this.contractAbiXDCEToken = contractDetails.XDCE_ABI[networkFrom];
    this.contractAbiSwap = contractDetails.ABI.SWAP[networkFrom];
    this.decimals = contractDetails.DECIMALS.TOKEN[networkFrom];
    this.contractAbiXDCESwap = contractDetails.XDCE_SWAP_ABI[networkFrom];
    this.contractAddressXDCESwap = contractDetails.XDCE_SWAP_ADDRESS[networkFrom];
    console.log('ContractService this.wallet',this.wallet)
    if (networkFrom==='Binance-Chain') return;
    if (networkFrom==='Xinfin') return;
    this.contractToken = this.wallet.getContract(this.contractAbiToken, this.contractAddressToken)
    this.contractSwap = this.wallet.getContract(this.contractAbiSwap, this.contractAddressSwap)
    this.contractTokenXDCE = this.wallet.getContract(this.contractAbiXDCEToken, this.contractAddressXDCEToken)
  }

  balanceOf = async (address, currentToken) => {
    // console.log('balanceOf',this.contractAddressToken,address,this.contractToken)
    if (currentToken === 'XDCE') {
      const xdceBalance = await this.contractTokenXDCE.methods.balanceOf(address).call();
      return +new BigNumber(xdceBalance).dividedBy(new BigNumber(10).pow(this.decimals)).toFixed()
    }
    const balance = await this.contractToken.methods.balanceOf(address).call();
    return +new BigNumber(balance).dividedBy(new BigNumber(10).pow(this.decimals)).toFixed()
  }

  allowance = async (address, currentToken) => {
    // console.log('allowance',address,this.contractAddressSwap)
    let allowance;
    if (currentToken === 'XDCE') {
         allowance = await  this.contractTokenXDCE.methods.allowance(address,this.contractAddressXDCESwap).call()
    }
    if (currentToken === 'Wish' || currentToken === 'Xinfin'){
       allowance = await this.contractToken.methods.allowance(address,this.contractAddressSwap).call()
    }
    return +new BigNumber(allowance).dividedBy(new BigNumber(10).pow(this.decimals)).toString(10)
  }

  totalSupply = async (currentToken) => {
    let totalSupply;
    if (currentToken === "Xinfin") {
      totalSupply = new BigNumber('100000000000').multipliedBy((new BigNumber(10).pow(this.decimals)).toString(10));
    }
    if (currentToken === 'XDCE') {
      const contractToken = this.wallet.getContract(this.contractAbiXDCEToken, this.contractAddressXDCEToken)
      totalSupply = await contractToken.methods.totalSupply().call()
    }
    if (currentToken === 'Wish'){
      totalSupply = await this.contractToken.methods.totalSupply().call()
    }
    console.log(22, totalSupply);
    console.log(33, +new BigNumber(totalSupply).dividedBy(new BigNumber(10).pow(this.decimals)).toString(10));
    console.log(44, new BigNumber(totalSupply).dividedBy(new BigNumber(10).pow(this.decimals)).toString(10));
    return +new BigNumber(totalSupply).dividedBy(new BigNumber(10).pow(this.decimals)).toString(10)
  }

  approveToken = async (address, callback, currentToken) => {
    console.log('currentToken', currentToken);
    const totalSupply = await this.totalSupply(currentToken);
    if (currentToken === 'XDCE') {
      this.wallet.approveToken(address, this.contractAddressXDCESwap, totalSupply, callback, currentToken);
    }
    if (currentToken === 'Wish' || currentToken === 'Xinfin'){
      this.wallet.approveToken(address, this.contractAddressSwap, totalSupply, callback, currentToken);
    }
  }

  transferFromBinanceChain = async ({ userAddress, blockchain, amount, receiver, }) => {
    try {
      const data = `${blockchain}${receiver}`;
      return await this.wallet.sendPlainTx({ addressFrom: userAddress, amount, data });
    } catch (e) {
      console.error(e);
    }
  }

  transferToOtherBlockchain = async ({ userAddress, blockchain, amount, receiver, callback, currentToken }) => {
    this.wallet.transferToOtherBlockchain({ userAddress, blockchain, amount, receiver, callback, currentToken })
  }

  depositXdceWithSignature = async ({amount, newAddress, signedAddress, signedAmounts, memorySignature, callback}) => {
    if (newAddress.slice(0,3) === 'xdc') {
      newAddress = '0x' + newAddress.slice(3);
    }
    this.wallet.depositXdceWithSignature({amount, newAddress, signedAddress, signedAmounts, memorySignature, callback});
  }

  depositXdce = async ({amount, newAddress, signedAddress, callback}) => {
    if (newAddress.slice(0,3) === 'xdc') {
      newAddress = '0x' + newAddress.slice(3);
    }
    this.wallet.depositXdce({amount, newAddress, signedAddress, callback});
  }

}
