const initialState = {
    isPending: false
}

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case 'PENDING:SET_STATE':
            return {
                ...state,
                ...payload
            };
        default:
            return state
    }
}
