import axios from 'axios';
import config from '../../config';
import BigNumber from "bignumber.js";
import Web3 from "web3";

const web3 = new Web3(window.ethereum);

export default class BackendService {

  constructor() {
    axios.defaults.baseURL = config.serverDomain();
    axios.defaults.headers.post['Content-Type'] = 'application/json';
  }

  getDexList = async (token) => {
    try {
      axios.defaults.baseURL = config.serverDomain(token);
      return axios.get('/networks/')
    } catch (e) {
      console.error(e);
    }
  }

  getDex = async ({name}) => {
    try {
      return axios.get(`/dex/${name}/`)
    } catch (e) {
      console.error(e);
    }
  }

  getGas = async ({network}) => {
    try {
      return axios.get(`/gas/${network}/`)
    } catch (e) {
      console.error(e);
    }
  }

  getMaximumXDCAmount = async (userAddress, amount) => {
    try {
      axios.defaults.baseURL = config.serverDomain('XDCE');
      if (!amount) {
        amount = 0;
      }
      return axios.post('/estimate_xdc_amount/', {'address': userAddress, 'xdceAmount': amount});
    } catch (e) {
      console.error(e);
    }
  }

  getMaximumXDCEAmount = async (userAddress) => {
    try {
      return axios.get(`/xdce_swap_limit/${userAddress}/`);
    } catch (e) {
      console.error(e);
    }
  }

  getXDCSignature = async (userAddress) => {
    try {
      axios.defaults.baseURL = config.serverDomain('XDCE');
      return axios.get(`/xdce_swap_limits_signature/${userAddress}/`);
    } catch (e) {
      console.error(e);
    }
  }

  isXDCSigned = async (userAddress) => {
    try {
      axios.defaults.baseURL = config.serverDomain('XDCE');
      return axios.get(`/is_xdce_swap_limits_stored_on_contract/${userAddress}/`);
    } catch (e) {
      console.error(e);
    }
  }
}
