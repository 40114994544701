export default {
  IS_PRODUCTION: false,
  IS_XINFIN: true,
  serverDomain(token) {
    (token === 'Xinfin' || token === 'XDCE') ? this.IS_XINFIN = true : this.IS_XINFIN = false;
    return (this.IS_PRODUCTION && !this.IS_XINFIN) ?
    'https://bridge.mywish.io/api/v1' :
    (this.IS_PRODUCTION && this.IS_XINFIN) ? 'https://xdc-bridge.mywish.io/api/v1' :
    (!this.IS_PRODUCTION && !this.IS_XINFIN) ? 'https://devdex.mywish.io/api/v1' : 'https://xdc-bridge.mywish.io/api/v1'

    // https://xdc-bridge.mywish.io/api/v1/networks/ XDC
    // 'https://devswaps.rubic.exchange/api/v1/networks/' wish
  },
  infuraId: '3ec9dc2892a247ad9f803bbff6dc7671',
  rpc() { return {
    1: `https://mainnet.infura.io/v3/${this.infuraId}`,
    3: `https://ropsten.infura.io/v3/${this.infuraId}`,
    38: "https://bsc-dataseed.binance.org",
    42: `https://kovan.infura.io/v3/${this.infuraId}`,
    56: "https://bsc-dataseed.binance.org",
    61: "https://data-seed-prebsc-1-s1.binance.org:8545",
    97: "https://data-seed-prebsc-1-s1.binance.org:8545",
  }},
  links: {
    facebook: 'https://www.facebook.com/MyWish.io/',
    twitter: 'https://twitter.com/mywishplatform',
    telegram: 'https://t.me/MyWish_platform',
    youtube: 'https://www.youtube.com/c/MyWishplatform',
    medium: 'https://cryptomywish.medium.com/',
    github: 'https://github.com/mywishplatform',
    bitcointalk: 'https://bitcointalk.org/index.php?topic=2059189.msg20545654#msg20545654',
    email: 'support@mywish.io',
    policy: 'https://mywish.io/privacy',
    terms: 'https://mywish.io/terms',
  },
  tokenLinks() {
    return {
      ethereum: this.IS_PRODUCTION ? 'https://etherscan.io' : 'https://ropsten.etherscan.io',
      binanceSmartChain: this.IS_PRODUCTION ? 'https://bscscan.com' : 'https://testnet.bscscan.com',
      binanceChain: this.IS_PRODUCTION ? 'https://explorer.binance.org' : 'https://testnet-explorer.binance.org',
      xinfin: this.IS_PRODUCTION ? 'https://explorer.xinfin.network/' : 'https://apothem.xinfinscan.com/',
    }
  }
}
